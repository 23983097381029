import afrodisiasImg2 from './assets/h_afrodisias2.webp';
import balikligolImg1 from './assets/h_balikligol1.webp';
import kapadokyaImg1 from './assets/h_kapadokya1.jpg';

const lList = [
    {
        id: "EN",
        title: "Tailor-made Destination Experience",
        metaDescription: "Turkey Istanbul DMC MICE Meeting Incentive Congress Event Cappadocia Gobeklitepe Pamukkale Ephesus",
        headerLeft: "",
        headerRight: "",
        subHeader: "",
        moreText: "more",
        photoCreditText: "Photo by",
        contact: "Please do not hesitate to contact us for your customized itinirary and quotation",
        cover: 
        [
            {
                img: [balikligolImg1],
                photoCredit: ["Arif Merter"],
                id: "About",
                header: "About Tailor-Made Experiences",
                text: [
                "In today's fast-paced corporate landscape, fostering meaningful connections with clients is more crucial than ever. Beyond traditional business meetings and conferences, companies are increasingly turning to tailor-made destination experiences to impress and engage their corporate clients. These bespoke journeys offer a unique opportunity to create lasting impressions, strengthen relationships, and cultivate loyalty in a competitive market.",
                "Gone are the days of one-size-fits-all corporate events. Instead, companies are recognizing the value of personalized experiences that cater to the specific interests and preferences of their clients. Whether it's a luxury retreat in a picturesque countryside, an adventure-filled excursion in a tropical paradise, or a cultural immersion in a vibrant city, tailor-made destination experiences are designed to leave a lasting impact.",
                "One of the key advantages of tailor-made destination experiences is the ability to align the itinerary with the client's objectives and goals. By understanding your interests, business needs, and cultural sensitivities, we can curate an experience that resonates with your clients on a deeper level. Our personalized approach demonstrates a commitment to your success and fosters a sense of appreciation and trust.",
                "Moreover, our tailor-made destination experiences offer ample opportunities for networking and relationship-building in a relaxed and informal setting. Whether it's sharing a meal overlooking a stunning landscape, participating in team-building activities, or exploring local attractions together, our experiences create memorable moments that strengthen bonds and foster camaraderie between our clients and their hosts.",
                "In addition to creating meaningful connections, our tailor-made destination experiences can also serve as a platform for showcasing your company's values, ethos, and corporate culture. From sustainable practices and community engagement initiatives to innovative technologies and industry leadership, we can integrate these elements into the itinerary to showcase your commitment to social responsibility and sustainability.",
                "Furthermore, our tailor-made destination experiences allow you to differentiate yourself from competitors and stand out in the minds of your clients. By offering unique and unforgettable experiences that go above and beyond your expectations, we can leave a lasting impression that sets you apart and reinforces your value proposition.",
                "To successfully execute tailor-made destination experiences, we pay attention to detail and prioritize the seamless delivery of every aspect of the itinerary. From transportation and accommodation to dining and entertainment, every element will meticulously planned and executed to ensure a flawless experience for our clients.",
                "In conclusion, our tailor-made destination experiences offer a compelling opportunity for you to create unforgettable moments and strengthen relationships with your clients. By personalizing the itinerary to align with your interests and objectives, we can demonstrate your commitment to your clients' success while fostering loyalty and trust. In today's competitive business landscape, investing in tailor-made destination experiences is not just a luxury but a strategic imperative for companies looking to thrive in the long term."
                ]
            },
            {
                img: [afrodisiasImg2],
                photoCredit: ["Arif Merter"],
                id: "MICE",
                header: "Unveiling the Power of MICE: Elevating Business through Meetings, Incentives, Congresses, and Events",
                text: [
                "In the dynamic landscape of global business, companies are constantly seeking innovative strategies to foster growth, drive productivity, and enhance collaboration. Among these strategies, the Meetings, Incentives, Congresses, and Events (MICE) industry stands out as a powerful catalyst for organizational success. By leveraging the potential of face-to-face interactions, strategic incentives, and curated events, MICE initiatives play a pivotal role in shaping business outcomes and driving sustainable development.",
                "More Than Just Gatherings",
                "The MICE industry encompasses a diverse array of activities designed to facilitate professional networking, knowledge exchange, and business development. From corporate meetings and conferences to incentive travel programs and large-scale exhibitions, MICE initiatives cater to the multifaceted needs of modern enterprises across various sectors.",
                "Meetings: At the heart of MICE lie meetings, serving as platforms for brainstorming, decision-making, and relationship-building. Whether small-scale team gatherings or expansive industry conferences, these events provide invaluable opportunities for professionals to exchange ideas, forge partnerships, and drive organizational objectives forward.",
                "Incentives: Incentive travel programs represent another integral aspect of the MICE landscape, designed to reward and motivate employees, partners, and clients. By offering unique travel experiences, incentives not only recognize individual and team achievements but also foster loyalty, boost morale, and stimulate performance within organizations.",
                "Congresses: Congresses or conventions serve as focal points for industry-wide discourse, bringing together thought leaders, stakeholders, and experts to address key issues, share insights, and shape future trends. These gatherings provide a platform for disseminating knowledge, showcasing innovations, and driving collective progress within specific sectors or professional communities.",
                "Events: From product launches and trade shows to gala dinners and corporate retreats, events constitute a diverse spectrum within the MICE domain. By blending creativity, branding, and strategic planning, these occasions serve as powerful tools for brand exposure, customer engagement, and market differentiation.",
                "The Impact of MICE on Business Success",
                "The significance of MICE initiatives extends far beyond mere logistical arrangements or social gatherings. When executed effectively, these activities have the potential to yield substantial benefits for organizations, including:",
                "1. Enhanced Networking and Collaboration: MICE events provide unparalleled opportunities for professionals to connect, collaborate, and cultivate meaningful relationships. By fostering a conducive environment for dialogue and interaction, these gatherings facilitate knowledge exchange, industry partnerships, and business synergies.",
                "2. Knowledge Sharing and Professional Development: Conferences, congresses, and seminars within the MICE sphere serve as valuable platforms for learning, skill enhancement, and professional development. Through keynote speeches, panel discussions, and workshops, participants gain insights into emerging trends, best practices, and innovative solutions relevant to their fields.",
                "3. Business Expansion and Market Penetration: For enterprises seeking to expand their reach and enter new markets, MICE initiatives offer strategic avenues for market research, brand exposure, and customer acquisition. By participating in trade fairs, exhibitions, and business forums, organizations can showcase their products, services, and capabilities to a diverse audience of potential clients and partners.",
                "4. Employee Engagement and Motivation: Incentive travel programs represent a powerful tool for recognizing and rewarding employee performance, fostering a culture of appreciation and motivation within organizations. By offering enticing travel experiences as incentives, companies can incentivize goal achievement, inspire loyalty, and enhance employee satisfaction and retention rates.",
                "5. Brand Building and Reputation Management: Through carefully curated events and congresses, organizations can enhance their brand visibility, reputation, and market positioning. By aligning event themes, messaging, and activities with their brand values and objectives, companies can create memorable experiences that resonate with stakeholders, strengthen brand affinity, and differentiate themselves in competitive markets.",
                "In conclusion, the MICE industry represents a dynamic and indispensable component of modern business strategy, offering a plethora of opportunities for organizations to connect, collaborate, and thrive in today's competitive landscape. By harnessing the power of meetings, incentives, congresses, and events, companies can unlock new avenues for growth, innovation, and success, driving sustainable development and positive impact across industries and communities worldwide."
                ]
            },
            {
                img: [kapadokyaImg1],
                photoCredit: ["Refik Can Apaydın"],
                id: "DMC",
                header: "Unlocking the Essence of Destination Management Companies",
                text: [
                "In the dynamic world of travel and tourism, where every journey is a unique narrative waiting to unfold, the role of Destination Management Companies (DMCs) stands out as paramount. Often operating behind the scenes, we play a crucial role in orchestrating seamless travel experiences for individuals, groups, and organizations alike. Let's delve into the essence of Destination Management Companies and explore how we shape the travel landscape.",
                "What is a Destination Management Company?",
                "A Destination Management Company, commonly referred to as a DMC, is a professional services provider specializing in the management and coordination of travel arrangements within a particular destination. From transportation logistics to accommodation, activities, and cultural experiences, DMCs handle a wide array of services tailored to meet the needs and desires of travelers.",
                "Our Role: Crafting Unforgettable Experiences",
                "At the heart of our mission lies the art of crafting unforgettable experiences. Unlike traditional travel agencies, which primarily focus on booking flights and hotels, we offer a comprehensive suite of services designed to immerse you in the essence of your chosen destination.",
                "1. Local Expertise: We possess an intimate knowledge of our respective destinations. Leveraging our expertise, we curate itineraries that showcase the hidden gems, cultural nuances and authentic experiences unique to each locale.",
                "2. Logistical Mastery: From airport transfers to navigating through bustling city streets or remote landscapes, we excel in managing the logistical complexities of travel. Our meticulous planning ensures that every aspect of the journey unfolds seamlessly, allowing you to focus on soaking in the experience.",
                "3. Tailored Experiences: Recognizing that every traveler is unique, we excel in customization. Whether it's a luxury retreat, an adventure-packed expedition or a cultural immersion tour, we tailor our offerings to align with the preferences and interests of our clients.",
                "4. Collaboration and Partnerships: We cultivate strong relationships with local vendors, suppliers, and service providers. These partnerships not only ensure access to exclusive experiences but also enable us to negotiate favorable terms, adding value to the overall travel experience.",
                "Bringing Destinations to Life",
                "- Personalized Itinerary: Drawing upon our local insights, we design a bespoke itinerary that balances must-see attractions with off-the-beaten-path experiences, ensuring a holistic exploration of each destination.",
                "- Effortless Logistics: We handle all aspects of transportation, accommodation and excursion bookings, eliminating the stress associated with travel planning. Whether it's arranging for luxury transfers or organizing guided tours, every detail is meticulously coordinated.",
                "- Cultural Immersion: Through curated experiences such as cooking classes with local chefs, visits to traditional markets or interactions with indigenous communities, we facilitate meaningful cultural exchanges, allowing you to gain a deeper understanding of the destinations you visit.",
                "- 24/7 Support: Throughout the journey, we provide round-the-clock support, ensuring that any unforeseen circumstances are swiftly addressed, thus enhancing the overall peace of mind for you.",
                "In conclusion, we play a pivotal role in shaping the travel landscape, offering unparalleled expertise, seamless logistics and curated experiences that transform ordinary journeys into extraordinary adventures. As the travel industry continues to evolve, we are adapting to meet the changing needs and expectations of our clients.",
                ],
                /* burada kullanmıyorum, ancak CoverItem.js bu formatı okuyup gösteriyor...
                itinirary: {
                    header: "Cappadocia Sample Itinirary",
                    show: "Show Sample Itinirary",
                    hide: "Close Sample Itinirary",
                    dayText: "Day",
                    days: [
                        {
                            lines: [
                                { text: "Kaymaklı Underground City" },
                                { text: "Pigeon Valley" },
                                { text: "Lunch" },
                                { text: "Uçhisar Village" },
                                { text: "Avcılar Viewpoint" }
                            ]
                        },
                        {
                            lines: [
                                { text: "Göreme open air museum - rock-hewn churches and monastery complex" },
                                { text: "Love Valley" },
                                { text: "Lunch" },
                                { text: "Ortahisar Village" },
                                { text: "Dervent Valley" }
                            ]
                        }
                    ]
                }
                */
            },
        ]
    },
    {
        id: "DE",
        title: "Maßgeschneiderte Reiseziel-Erlebnisse",
        metaDescription: "Türkei Istanbul DMC MICE Meeting Incentive Kongressveranstaltung Kappadokien Gobeklitepe Pamukkale Ephesus",
        headerLeft: "",
        headerRight: "",
        subHeader: "",
        moreText: "mehr",
        photoCreditText: "Foto von",
        contact: "Bitte zögern Sie nicht, uns für Ihre individuelle Reiseroute und Ihr Angebot zu kontaktieren",
        cover: 
        [
            {
                img: [balikligolImg1],
                photoCredit: ["Arif Merter"],
                id: "About",
                header: "Über maßgeschneiderte Erlebnisse",
                text: [
                "In der heutigen schnelllebigen Unternehmenslandschaft ist die Pflege sinnvoller Kundenbeziehungen wichtiger denn je. Über traditionelle Geschäftstreffen und Konferenzen hinaus greifen Unternehmen zunehmend auf maßgeschneiderte Reisezielerlebnisse zurück, um ihre Firmenkunden zu beeindrucken und zu begeistern. Diese maßgeschneiderten Reisen bieten eine einzigartige Gelegenheit, bleibende Eindrücke zu hinterlassen, Beziehungen zu stärken und Loyalität in einem wettbewerbsintensiven Markt zu pflegen.",
                "Vorbei sind die Zeiten einheitlicher Unternehmensveranstaltungen. Stattdessen erkennen Unternehmen den Wert personalisierter Erlebnisse, die auf die spezifischen Interessen und Vorlieben ihrer Kunden zugeschnitten sind. Ob es sich um einen luxuriösen Rückzugsort in einer malerischen Landschaft oder ein Abenteuer handelt. Ob ein Ausflug in ein tropisches Paradies oder ein kulturelles Eintauchen in eine pulsierende Stadt – maßgeschneiderte Reisezielerlebnisse sollen einen bleibenden Eindruck hinterlassen.",
                "Einer der Hauptvorteile maßgeschneiderter Reisezielerlebnisse ist die Möglichkeit, die Reiseroute an den Zielen und Zielstellungen des Kunden auszurichten. Indem wir Ihre Interessen, Geschäftsanforderungen und kulturellen Empfindlichkeiten verstehen, können wir ein Erlebnis kuratieren, das bei Ihren Kunden Anklang findet.“ tiefere Ebene. Unser personalisierter Ansatz zeigt Engagement für Ihren Erfolg und fördert ein Gefühl der Wertschätzung und des Vertrauens.",
                "Darüber hinaus bieten unsere maßgeschneiderten Reisezielerlebnisse zahlreiche Möglichkeiten zum Netzwerken und zum Aufbau von Beziehungen in einer entspannten und ungezwungenen Atmosphäre. Ob Sie gemeinsam eine Mahlzeit mit Blick auf eine atemberaubende Landschaft einnehmen, an Teambuilding-Aktivitäten teilnehmen oder gemeinsam lokale Sehenswürdigkeiten erkunden – unsere Erlebnisse Schaffen Sie unvergessliche Momente, die die Bindungen stärken und die Kameradschaft zwischen unseren Kunden und ihren Gastgebern fördern.",
                "Unsere maßgeschneiderten Reisezielerlebnisse schaffen nicht nur sinnvolle Verbindungen, sondern können auch als Plattform für die Darstellung der Werte, des Ethos und der Unternehmenskultur Ihres Unternehmens dienen. Von nachhaltigen Praktiken und Initiativen zum Engagement in der Gemeinschaft bis hin zu innovativen Technologien und Branchenführerschaft können wir diese integrieren.“ Integrieren Sie Elemente in die Reiseroute, um Ihr Engagement für soziale Verantwortung und Nachhaltigkeit zu demonstrieren.",
                "Darüber hinaus ermöglichen Ihnen unsere maßgeschneiderten Reisezielerlebnisse, sich von der Konkurrenz abzuheben und in den Köpfen Ihrer Kunden hervorzustechen. Indem wir einzigartige und unvergessliche Erlebnisse bieten, die Ihre Erwartungen übertreffen, können wir einen bleibenden Eindruck hinterlassen, der Sie von anderen abhebt.“ und stärkt Ihr Wertversprechen.",
                "Um maßgeschneiderte Reisezielerlebnisse erfolgreich umzusetzen, achten wir auf Details und legen Wert auf die nahtlose Umsetzung jedes Aspekts der Reiseroute. Von Transport und Unterkunft bis hin zu Essen und Unterhaltung wird jedes Element sorgfältig geplant und ausgeführt, um ein makelloses Erlebnis für unsere Kunden zu gewährleisten.",
                "Zusammenfassend lässt sich sagen, dass unsere maßgeschneiderten Reisezielerlebnisse eine überzeugende Gelegenheit für Sie bieten, unvergessliche Momente zu schaffen und die Beziehungen zu Ihren Kunden zu stärken. Indem wir die Reiseroute individuell an Ihre Interessen und Ziele anpassen, können wir gleichzeitig Ihr Engagement für den Erfolg Ihrer Kunden unter Beweis stellen, sowie Loyalität und Vertrauen fördern. In der heutigen wettbewerbsintensiven Geschäftslandschaft ist die Investition in maßgeschneiderte Reisezielerlebnisse nicht nur ein Luxus, sondern eine strategische Notwendigkeit für Unternehmen, die langfristig erfolgreich sein wollen."
                ]
            },
            {
                img: [afrodisiasImg2],
                photoCredit: ["Arif Merter"],
                id: "MICE",
                header: "Enthüllen Sie die Leistungsfähigkeit von MICE: Steigern Sie Ihr Geschäft durch Meetings, Incentives, Kongresse und Veranstaltungen",
                text: [
                "In der dynamischen Welt des globalen Geschäfts suchen Unternehmen ständig nach innovativen Strategien, um Wachstum zu fördern, die Produktivität zu steigern und die Zusammenarbeit zu verbessern. Unter diesen Strategien sticht die Meetings-, Incentives-, Kongress- und Eventbranche (MICE) als starker Katalysator für den Unternehmenserfolg hervor. Durch die Nutzung des Potenzials persönlicher Interaktionen, strategischer Anreize und kuratierter Veranstaltungen spielen MICE-Initiativen eine entscheidende Rolle bei der Gestaltung von Geschäftsergebnissen und der Förderung einer nachhaltigen Entwicklung.",
                "Mehr als nur Zusammenkünfte",
                "Die MICE-Branche umfasst eine Vielzahl von Aktivitäten, die darauf abzielen, professionelles Networking, Wissensaustausch und Geschäftsentwicklung zu erleichtern. Von Firmenmeetings und Konferenzen bis hin zu Incentive-Reiseprogrammen und Großausstellungen – MICE-Initiativen richten sich an die vielfältigen Bedürfnisse moderner Unternehmen in verschiedenen Branchen.",
                "Meetings: Das Herzstück von MICE sind Meetings, die als Plattformen für Brainstorming, Entscheidungsfindung und Beziehungsaufbau dienen. Ob kleine Teamtreffen oder umfangreiche Branchenkonferenzen – diese Veranstaltungen bieten Fachleuten wertvolle Möglichkeiten, Ideen auszutauschen, Partnerschaften zu knüpfen und Unternehmensziele voranzutreiben.",
                "Anreize: Incentive-Reiseprogramme stellen einen weiteren integralen Aspekt der MICE-Landschaft dar und sollen Mitarbeiter, Partner und Kunden belohnen und motivieren. Durch das Angebot einzigartiger Reiseerlebnisse würdigen Incentives nicht nur die Leistungen von Einzelpersonen und Teams, sondern fördern auch die Loyalität, steigern die Moral und steigern die Leistung innerhalb von Organisationen.",
                "Kongresse: Kongresse oder Tagungen dienen als Brennpunkte für den branchenweiten Diskurs und bringen Vordenker, Interessenvertreter und Experten zusammen, um wichtige Themen anzusprechen, Erkenntnisse auszutauschen und zukünftige Trends zu gestalten. Diese Zusammenkünfte bieten eine Plattform für die Verbreitung von Wissen, die Präsentation von Innovationen und die Förderung des kollektiven Fortschritts in bestimmten Sektoren oder Berufsgemeinschaften.",
                "Veranstaltungen: Von Produkteinführungen und Messen bis hin zu Galadinners und Firmenveranstaltungen bilden Veranstaltungen ein vielfältiges Spektrum im MICE-Bereich. Durch die Kombination von Kreativität, Branding und strategischer Planung dienen diese Anlässe als wirkungsvolle Instrumente zur Markenpräsenz, Kundenbindung und Marktdifferenzierung.",
                "Der Einfluss von MICE auf den Geschäftserfolg",
                "Die Bedeutung von MICE-Initiativen geht weit über bloße logistische Arrangements oder gesellschaftliche Zusammenkünfte hinaus. Bei effektiver Umsetzung können diese Aktivitäten erhebliche Vorteile für Organisationen mit sich bringen, darunter:",
                "1. Verbesserte Vernetzung und Zusammenarbeit: MICE-Veranstaltungen bieten beispiellose Möglichkeiten für Fachleute, Kontakte zu knüpfen, zusammenzuarbeiten und sinnvolle Beziehungen zu pflegen. Durch die Förderung eines förderlichen Umfelds für Dialog und Interaktion erleichtern diese Zusammenkünfte den Wissensaustausch, Branchenpartnerschaften und Geschäftssynergien.",
                "2. Wissensaustausch und berufliche Weiterentwicklung: Konferenzen, Kongresse und Seminare im MICE-Bereich dienen als wertvolle Plattformen zum Lernen, zur Kompetenzerweiterung und zur beruflichen Weiterentwicklung. Durch Keynote-Vorträge, Podiumsdiskussionen und Workshops erhalten die Teilnehmer Einblicke in neue Trends, Best Practices und innovative Lösungen, die für ihre Fachgebiete relevant sind.",
                "3. Geschäftsausweitung und Marktdurchdringung: Für Unternehmen, die ihre Reichweite erweitern und neue Märkte erschließen möchten, bieten MICE-Initiativen strategische Möglichkeiten für Marktforschung, Markenpräsenz und Kundenakquise. Durch die Teilnahme an Messen, Ausstellungen und Geschäftsforen können Unternehmen ihre Produkte, Dienstleistungen und Fähigkeiten einem vielfältigen Publikum potenzieller Kunden und Partner präsentieren.",
                "4. Mitarbeiterengagement und -motivation: Incentive-Reiseprogramme stellen ein wirksames Instrument zur Anerkennung und Belohnung der Mitarbeiterleistung dar und fördern eine Kultur der Wertschätzung und Motivation innerhalb von Unternehmen. Durch das Anbieten verlockender Reiseerlebnisse als Anreize können Unternehmen Anreize für das Erreichen von Zielen schaffen, Loyalität wecken und die Zufriedenheit und Bindungsrate der Mitarbeiter steigern.",
                "5. Markenaufbau und Reputationsmanagement: Durch sorgfältig kuratierte Veranstaltungen und Kongresse können Unternehmen ihre Markensichtbarkeit, ihren Ruf und ihre Marktpositionierung verbessern. Durch die Abstimmung von Veranstaltungsthemen, Botschaften und Aktivitäten auf ihre Markenwerte und -ziele können Unternehmen unvergessliche Erlebnisse schaffen, die bei den Stakeholdern Anklang finden, die Markenaffinität stärken und sich auf wettbewerbsintensiven Märkten differenzieren.",
                "Zusammenfassend lässt sich sagen, dass die MICE-Branche einen dynamischen und unverzichtbaren Bestandteil moderner Geschäftsstrategien darstellt und Unternehmen eine Fülle von Möglichkeiten bietet, sich in der heutigen Wettbewerbslandschaft zu vernetzen, zusammenzuarbeiten und erfolgreich zu sein. Durch die Nutzung der Kraft von Meetings, Incentives, Kongressen und Veranstaltungen können Unternehmen neue Wege für Wachstum, Innovation und Erfolg erschließen und so eine nachhaltige Entwicklung und positive Auswirkungen in allen Branchen und Gemeinschaften weltweit vorantreiben."
                ]
            },
            {
                img: [kapadokyaImg1],
                photoCredit: ["Refik Can Apaydın"],
                id: "DMC",
                header: "Entdecken Sie die Essenz von Destination-Management-Unternehmen",
                text: [
                "In der dynamischen Welt des Reisens und Tourismus, in der jede Reise eine einzigartige Erzählung ist, die nur darauf wartet, sich zu entfalten, ist die Rolle von Destination Management Companies (DMCs) von größter Bedeutung. Wir agieren oft hinter den Kulissen und spielen eine entscheidende Rolle bei der Orchestrierung nahtloser Reiseerlebnisse für Einzelpersonen, Gruppen und Organisationen gleichermaßen. Lassen Sie uns in die Essenz von Destination Management Companies eintauchen und erkunden, wie wir die Reiselandschaft gestalten.",
                "Was ist eine Destination Management Company?",
                "Eine Destination Management Company, allgemein als DMC bezeichnet, ist ein professioneller Dienstleister, der sich auf die Verwaltung und Koordination von Reisearrangements innerhalb eines bestimmten Reiseziels spezialisiert hat. Von der Transportlogistik bis hin zu Unterkünften, Aktivitäten und kulturellen Erlebnissen bieten DMCs eine breite Palette von Dienstleistungen an, die auf die Bedürfnisse und Wünsche der Reisenden zugeschnitten sind.",
                "Unsere Aufgabe: Unvergessliche Erlebnisse schaffen",
                "Im Mittelpunkt unserer Mission steht die Kunst, unvergessliche Erlebnisse zu schaffen. Im Gegensatz zu herkömmlichen Reisebüros, die sich in erster Linie auf die Buchung von Flügen und Hotels konzentrieren, bieten wir eine umfassende Palette an Dienstleistungen an, die darauf ausgelegt sind, Sie in die Essenz Ihres gewählten Reiseziels einzutauchen.",
                "1. Lokale Expertise: Wir verfügen über umfassende Kenntnisse unserer jeweiligen Reiseziele. Mithilfe unseres Fachwissens kuratieren wir Reiserouten, die die verborgenen Schätze, kulturellen Nuancen und authentischen Erlebnisse zeigen, die für jeden Ort einzigartig sind.",
                "2. Logistische Beherrschung: Von Flughafentransfers bis hin zur Navigation durch geschäftige Stadtstraßen oder abgelegene Landschaften – wir beherrschen die logistischen Komplexitäten des Reisens hervorragend. Unsere sorgfältige Planung stellt sicher, dass jeder Aspekt der Reise reibungslos verläuft, sodass Sie sich auf das Eintauchen in das Erlebnis konzentrieren können.",
                "3. Maßgeschneiderte Erlebnisse: Da jeder Reisende einzigartig ist, zeichnen wir uns durch individuelle Anpassung aus. Ganz gleich, ob es sich um einen luxuriösen Rückzugsort, eine abenteuerreiche Expedition oder eine Kulturreise handelt, wir passen unsere Angebote an die Vorlieben und Interessen unserer Kunden an.",
                "4. Zusammenarbeit und Partnerschaften: Wir pflegen enge Beziehungen zu lokalen Anbietern, Lieferanten und Dienstleistern. Diese Partnerschaften gewährleisten nicht nur den Zugang zu exklusiven Erlebnissen, sondern ermöglichen uns auch die Aushandlung günstiger Konditionen, die das Reiseerlebnis insgesamt aufwerten.",
                "Reiseziele zum Leben erwecken",
                "- Personalisierte Reiseroute: Basierend auf unseren lokalen Erkenntnissen entwerfen wir eine maßgeschneiderte Reiseroute, die Attraktionen, die man gesehen haben muss, mit Erlebnissen abseits der ausgetretenen Pfade verbindet und so eine ganzheitliche Erkundung jedes Reiseziels gewährleistet.",
                "- Mühelose Logistik: Wir kümmern uns um alle Aspekte der Transport-, Unterkunfts- und Ausflugsbuchungen und eliminieren so den mit der Reiseplanung verbundenen Stress. Ob es um die Organisation von Luxustransfers oder die Organisation von Führungen geht, jedes Detail wird sorgfältig koordiniert.",
                "- Kulturelles Eintauchen: Durch kuratierte Erlebnisse wie Kochkurse mit lokalen Köchen, Besuche traditioneller Märkte oder Interaktionen mit indigenen Gemeinschaften ermöglichen wir einen sinnvollen kulturellen Austausch, der es Ihnen ermöglicht, ein tieferes Verständnis für die von Ihnen besuchten Reiseziele zu erlangen.",
                "- Support rund um die Uhr: Während der gesamten Reise bieten wir rund um die Uhr Support, um sicherzustellen, dass unvorhergesehene Umstände schnell behoben werden, und sorgen so für mehr Sicherheit für Sie.",
                "Zusammenfassend lässt sich sagen, dass wir eine entscheidende Rolle bei der Gestaltung der Reiselandschaft spielen und beispiellose Expertise, nahtlose Logistik und kuratierte Erlebnisse bieten, die gewöhnliche Reisen in außergewöhnliche Abenteuer verwandeln. Da sich die Reisebranche ständig weiterentwickelt, passen wir uns an die sich ändernden Bedürfnisse und Erwartungen unserer Kunden an.",
                ],
                /* burada kullanmıyorum, ancak CoverItem.js bu formatı okuyup gösteriyor...
                itinirary: {
                    header: "Kappadokien Beispielreiseroute",
                    show: "Beispielroute anzeigen",
                    hide: "Beispielroute schließen",
                    dayText: "Tag",
                    days: [
                        {
                            lines: [
                                { text: "Unterirdische Stadt Kaymaklı" },
                                { text: "Taubental" },
                                { text: "Mittagessen" },
                                { text: "Dorf Uçhisar" },
                                { text: "Aussichtspunkt Avcılar" }
                            ]
                        },
                        {
                            lines: [
                                { text: "Göreme Freilichtmuseum - Felsenkirchen und Klosteranlage" },
                                { text: "Liebestal" },
                                { text: "Mittagessen" },
                                { text: "Ortahisar Dorf" },
                                { text: "Dervent Valley" }
                            ]
                        }
                    ]
                }
                */
            },
        ]
    },
    {
        id: "FR",
        title: "Expérience de destination sur mesure",
        metaDescription: "Turquie Istanbul DMC MICE Réunion Incentive Congrès Événement Cappadoce Gobeklitepe Pamukkale Éphèse",
        headerLeft: "",
        headerRight: "",
        subHeader: "",
        moreText: "suite",
        photoCreditText: "Photo par",
        contact: "N'hésitez pas à nous contacter pour votre itinéraire et devis sur mesure",
        cover: 
        [
            {
                img: [balikligolImg1],
                photoCredit: ["Arif Merter"],
                id: "About",
                header: "À propos des expériences sur mesure",
                text: [
                "Dans le paysage corporatif actuel en évolution rapide, il est plus crucial que jamais de favoriser des liens significatifs avec les clients. Au-delà des réunions et conférences d'affaires traditionnelles, les entreprises se tournent de plus en plus vers des expériences de destination sur mesure pour impressionner et impliquer leurs entreprises clientes. Ces voyages sur mesure offrent une opportunité unique de créer des impressions durables, de renforcer les relations et de cultiver la fidélité dans un marché concurrentiel.",
                "Il est révolu le temps des événements d’entreprise à taille unique. Au lieu de cela, les entreprises reconnaissent la valeur des expériences personnalisées qui répondent aux intérêts et préférences spécifiques de leurs clients. Qu'il s'agisse d'une retraite de luxe dans une campagne pittoresque, d'une excursion pleine d'aventures dans un paradis tropical ou d'une immersion culturelle dans une ville animée, les expériences de destination sur mesure sont conçues pour laisser un impact durable.",
                "L'un des principaux avantages des expériences de destination sur mesure est la capacité d'aligner l'itinéraire sur les objectifs et les buts du client. En comprenant vos intérêts, vos besoins commerciaux et vos sensibilités culturelles, nous pouvons organiser une expérience qui résonne plus profondément auprès de vos clients. Notre approche personnalisée démontre un engagement envers votre réussite et favorise un sentiment d’appréciation et de confiance.",
                "De plus, nos expériences de destination sur mesure offrent de nombreuses opportunités de réseautage et d'établissement de relations dans un cadre détendu et informel. Qu'il s'agisse de partager un repas avec vue sur un paysage magnifique, de participer à des activités de consolidation d'équipe ou d'explorer ensemble les attractions locales, nos expériences créent des moments mémorables qui renforcent les liens et favorisent la camaraderie entre nos clients et leurs hôtes.",
                "En plus de créer des liens significatifs, nos expériences de destination sur mesure peuvent également servir de plate-forme pour mettre en valeur les valeurs, la philosophie et la culture d'entreprise de votre entreprise. Des pratiques durables et initiatives d'engagement communautaire aux technologies innovantes et au leadership de l'industrie, nous pouvons intégrer ces éléments dans l'itinéraire pour mettre en valeur votre engagement envers la responsabilité sociale et la durabilité.",
                "De plus, nos expériences de destination sur mesure vous permettent de vous différencier de vos concurrents et de vous démarquer dans l'esprit de vos clients. En offrant des expériences uniques et inoubliables qui dépassent vos attentes, nous pouvons laisser une impression durable qui vous distingue et renforce votre proposition de valeur.",
                "Pour réaliser avec succès des expériences de destination sur mesure, nous prêtons attention aux détails et accordons la priorité à la livraison transparente de chaque aspect de l'itinéraire. Du transport à l'hébergement en passant par la restauration et le divertissement, chaque élément sera méticuleusement planifié et exécuté pour garantir une expérience sans faille à nos clients.",
                "En conclusion, nos expériences de destination sur mesure vous offrent une opportunité incontournable de créer des moments inoubliables et de renforcer les relations avec vos clients. En personnalisant l'itinéraire en fonction de vos intérêts et de vos objectifs, nous pouvons démontrer votre engagement envers le succès de vos clients tout en favorisant la fidélité et la confiance. Dans le paysage commercial concurrentiel d'aujourd'hui, investir dans des expériences de destination sur mesure n'est pas seulement un luxe mais un impératif stratégique pour les entreprises qui cherchent à prospérer sur le long terme."
                ]
            },
            {
                img: [afrodisiasImg2],
                photoCredit: ["Arif Merter"],
                id: "MICE",
                header: "Dévoiler la puissance du MICE : élever les affaires grâce à des réunions, des incitations, des congrès et des événements",
                text: [
                "Dans le paysage dynamique du commerce mondial, les entreprises sont constamment à la recherche de stratégies innovantes pour favoriser la croissance, stimuler la productivité et améliorer la collaboration. Parmi ces stratégies, le secteur des réunions, motivations, congrès et événements (MICE) se distingue comme un puissant catalyseur de réussite organisationnelle. En tirant parti du potentiel des interactions en face-à-face, des incitations stratégiques et des événements organisés, les initiatives MICE jouent un rôle central dans l'élaboration des résultats commerciaux et la promotion du développement durable.",
                "Plus que de simples rassemblements",
                "L'industrie MICE englobe un large éventail d'activités conçues pour faciliter le réseautage professionnel, l'échange de connaissances et le développement commercial. Des réunions et conférences d'entreprise aux programmes de voyages de motivation et aux expositions à grande échelle, les initiatives MICE répondent aux besoins multiformes des entreprises modernes dans divers secteurs.",
                "Réunions : au cœur du MICE se trouvent les réunions, qui servent de plateformes de brainstorming, de prise de décision et d'établissement de relations. Qu'il s'agisse de réunions d'équipe à petite échelle ou de grandes conférences sectorielles, ces événements offrent aux professionnels des opportunités inestimables d'échanger des idées, de forger des partenariats et de faire avancer les objectifs organisationnels.",
                "Incitations : les programmes de voyages d'incitation représentent un autre aspect intégral du paysage MICE, conçus pour récompenser et motiver les employés, les partenaires et les clients. En offrant des expériences de voyage uniques, les incitations reconnaissent non seulement les réalisations individuelles et collectives, mais favorisent également la fidélité, remontent le moral et stimulent la performance au sein des organisations.",
                "Congrès : les congrès ou conventions servent de points focaux pour le discours à l'échelle de l'industrie, réunissant des leaders d'opinion, des parties prenantes et des experts pour aborder des questions clés, partager des idées et façonner les tendances futures. Ces rassemblements offrent une plateforme pour diffuser les connaissances, présenter les innovations et stimuler le progrès collectif au sein de secteurs ou de communautés professionnelles spécifiques.",
                "Événements : des lancements de produits et salons professionnels aux dîners de gala et retraites d'entreprise, les événements constituent un spectre diversifié dans le domaine MICE. En alliant créativité, image de marque et planification stratégique, ces occasions constituent des outils puissants pour l'exposition de la marque, l'engagement des clients et la différenciation du marché.",
                "L'impact du MICE sur la réussite des entreprises",
                "L’importance des initiatives MICE s’étend bien au-delà des simples arrangements logistiques ou des rassemblements sociaux. Lorsqu’elles sont exécutées efficacement, ces activités peuvent potentiellement générer des avantages substantiels pour les organisations, notamment :",
                "1. Réseautage et collaboration améliorés : les événements MICE offrent aux professionnels des opportunités sans précédent de se connecter, de collaborer et d'entretenir des relations significatives. En favorisant un environnement propice au dialogue et à l'interaction, ces rassemblements facilitent l'échange de connaissances, les partenariats industriels et les synergies commerciales.",
                "2. Partage des connaissances et développement professionnel : les conférences, congrès et séminaires dans la sphère MICE constituent des plates-formes précieuses pour l'apprentissage, l'amélioration des compétences et le développement professionnel. Grâce à des discours d'ouverture, des tables rondes et des ateliers, les participants acquièrent un aperçu des tendances émergentes, des meilleures pratiques et des solutions innovantes pertinentes dans leur domaine.",
                "3. Expansion commerciale et pénétration du marché : pour les entreprises cherchant à étendre leur portée et à pénétrer de nouveaux marchés, les initiatives MICE offrent des voies stratégiques pour les études de marché, la visibilité de la marque et l'acquisition de clients. En participant à des salons professionnels, des expositions et des forums d'affaires, les organisations peuvent présenter leurs produits, services et capacités à un public diversifié de clients et partenaires potentiels.",
                "4. Engagement et motivation des employés : les programmes de voyages de motivation représentent un outil puissant pour reconnaître et récompenser la performance des employés, favorisant une culture d'appréciation et de motivation au sein des organisations. En proposant des expériences de voyage attrayantes comme incitations, les entreprises peuvent inciter à atteindre leurs objectifs, inspirer la fidélité et améliorer les taux de satisfaction et de rétention des employés.",
                "5. Création de marque et gestion de la réputation : grâce à des événements et des congrès soigneusement organisés, les organisations peuvent améliorer la visibilité, la réputation et le positionnement de leur marque sur le marché. En alignant les thèmes, les messages et les activités des événements avec les valeurs et les objectifs de leur marque, les entreprises peuvent créer des expériences mémorables qui trouvent un écho auprès des parties prenantes, renforcer l'affinité avec la marque et se différencier sur des marchés concurrentiels.",
                "En conclusion, l'industrie MICE représente un élément dynamique et indispensable de la stratégie commerciale moderne, offrant une multitude d'opportunités aux organisations pour se connecter, collaborer et prospérer dans le paysage concurrentiel d'aujourd'hui. En exploitant le pouvoir des réunions, des incitations, des congrès et des événements, les entreprises peuvent ouvrir de nouvelles voies de croissance, d'innovation et de réussite, favorisant ainsi le développement durable et un impact positif dans les secteurs et les communautés du monde entier."
                ]
            },
            {
                img: [kapadokyaImg1],
                photoCredit: ["Refik Can Apaydın"],
                id: "DMC",
                header: "Libérer l’essence des sociétés de gestion de destinations",
                text: [
                "Dans le monde dynamique du voyage et du tourisme, où chaque voyage est un récit unique qui attend de se dérouler, le rôle des sociétés de gestion de destination (DMC) apparaît primordial. Opérant souvent en coulisses, nous jouons un rôle crucial dans l’orchestration d’expériences de voyage fluides pour les individus, les groupes et les organisations. Plongeons dans l'essence des sociétés de gestion de destination et explorons comment nous façonnons le paysage du voyage.",
                "Qu’est-ce qu’une société de gestion de destination ?",
                "Une société de gestion de destination, communément appelée DMC, est un fournisseur de services professionnels spécialisé dans la gestion et la coordination des préparatifs de voyage au sein d'une destination particulière. De la logistique du transport à l'hébergement, en passant par les activités et les expériences culturelles, les DMC gèrent un large éventail de services adaptés pour répondre aux besoins et aux désirs des voyageurs.",
                "Notre rôle : créer des expériences inoubliables",
                "Au cœur de notre mission se trouve l’art de créer des expériences inoubliables. Contrairement aux agences de voyages traditionnelles, qui se concentrent principalement sur la réservation de vols et d'hôtels, nous proposons une gamme complète de services conçus pour vous plonger dans l'essence de la destination que vous avez choisie.",
                "1. Expertise locale : Nous possédons une connaissance intime de nos destinations respectives. Tirant parti de notre expertise, nous organisons des itinéraires qui mettent en valeur les joyaux cachés, les nuances culturelles et les expériences authentiques uniques à chaque lieu.",
                "2. Maîtrise logistique : Des transferts aéroportuaires à la navigation dans les rues animées de la ville ou dans des paysages isolés, nous excellons dans la gestion des complexités logistiques des voyages. Notre planification méticuleuse garantit que chaque aspect du voyage se déroule de manière transparente, vous permettant de vous concentrer sur l'immersion dans l'expérience.",
                "3. Expériences sur mesure : Reconnaissant que chaque voyageur est unique, nous excellons dans la personnalisation. Qu'il s'agisse d'une retraite de luxe, d'une expédition pleine d'aventures ou d'un circuit d'immersion culturelle, nous adaptons nos offres en fonction des préférences et des intérêts de nos clients.",
                "4. Collaboration et partenariats : nous entretenons des relations solides avec les vendeurs, fournisseurs et prestataires de services locaux. Ces partenariats garantissent non seulement l'accès à des expériences exclusives, mais nous permettent également de négocier des conditions avantageuses, ajoutant ainsi de la valeur à l'expérience de voyage globale.",
                "Donner vie aux destinations",
                "- Itinéraire personnalisé : en nous appuyant sur nos connaissances locales, nous concevons un itinéraire sur mesure qui équilibre les attractions incontournables et les expériences hors des sentiers battus, garantissant une exploration holistique de chaque destination.",
                "- Logistique sans effort : nous gérons tous les aspects des réservations de transport, d'hébergement et d'excursions, éliminant ainsi le stress associé à la planification du voyage. Qu'il s'agisse d'organiser des transferts de luxe ou d'organiser des visites guidées, chaque détail est méticuleusement coordonné.",
                "- Immersion culturelle : grâce à des expériences organisées telles que des cours de cuisine avec des chefs locaux, des visites de marchés traditionnels ou des interactions avec des communautés autochtones, nous facilitons des échanges culturels significatifs, vous permettant de mieux comprendre les destinations que vous visitez.",
                "- Assistance 24h/24 et 7j/7 : tout au long du voyage, nous fournissons une assistance 24 heures sur 24, garantissant que toute circonstance imprévue soit rapidement résolue, améliorant ainsi votre tranquillité d'esprit globale.",
                "En conclusion, nous jouons un rôle central dans l’évolution du paysage du voyage, en offrant une expertise inégalée, une logistique transparente et des expériences organisées qui transforment les voyages ordinaires en aventures extraordinaires. À mesure que l'industrie du voyage continue d'évoluer, nous nous adaptons pour répondre aux besoins et attentes changeants de nos clients.",
                    ],
                /* burada kullanmıyorum, ancak CoverItem.js bu formatı okuyup gösteriyor...                itinirary: {
                    header: "Exemple d'itinéraire en Cappadoce",
                    show: "Afficher un exemple d'itinéraire",
                    hide: "Fermer Exemple d'itinéraire",
                    dayText: "Jour",
                    days: [
                        {
                            lines: [
                                { text: "La ville souterraine de Kaymakli" },
                                { text: "Vallée des Pigeons" },
                                { text: "Déjeuner" },
                                { text: "Village d'Uçhisar" },
                                { text: "Point de vue d'Avcılar" }
                            ]
                        },
                        {
                            lines: [
                                { text: "Musée en plein air de Göreme - églises creusées dans le roc et complexe monastique" },
                                { text: "Vallée de l'amour" },
                                { text: "Déjeuner" },
                                { text: "Village d'Ortahisar" },
                                { text: "Vallée de la Dervent" }
                            ]
                        }
                    ]
                }
                */
            },
        ]
    },
    {
        id: "ES",
        title: "Experiencia de destino a medida",
        metaDescription: "Turquía Estambul DMC MICE Reunión Congreso de Incentivos Evento Capadocia Gobeklitepe Pamukkale Éfeso",
        headerLeft: "",
        headerRight: "",
        subHeader: "",
        moreText: "más",
        photoCreditText: "Foto por",
        contact: "No dude en ponerse en contacto con nosotros para obtener un itinerario y una cotización personalizados",
        cover: 
        [
            {
                img: [balikligolImg1],
                photoCredit: ["Arif Merter"],
                id: "About",
                header: "Acerca de las experiencias a medida",
                text: [
                "En el acelerado panorama corporativo actual, fomentar conexiones significativas con los clientes es más crucial que nunca. Más allá de las tradicionales reuniones y conferencias de negocios, las empresas recurren cada vez más a experiencias de destino personalizadas para impresionar e involucrar a sus clientes corporativos. Estos viajes personalizados ofrecen una oportunidad única para crear impresiones duraderas, fortalecer relaciones y cultivar la lealtad en un mercado competitivo.",
                "Atrás quedaron los días de los eventos corporativos únicos. En cambio, las empresas están reconociendo el valor de las experiencias personalizadas que atienden a los intereses y preferencias específicos de sus clientes. Ya sea un retiro de lujo en un paisaje pintoresco, una excursión llena de aventuras en un paraíso tropical o una inmersión cultural en una ciudad vibrante, las experiencias de destino hechas a medida están diseñadas para dejar un impacto duradero.",
                "Una de las ventajas clave de las experiencias de destino hechas a medida es la capacidad de alinear el itinerario con los objetivos y metas del cliente. Al comprender sus intereses, necesidades comerciales y sensibilidades culturales, podemos crear una experiencia que resuene con sus clientes en un nivel más profundo. Nuestro enfoque personalizado demuestra un compromiso con su éxito y fomenta un sentido de aprecio y confianza.",
                "Además, nuestras experiencias de destino personalizadas ofrecen amplias oportunidades para establecer contactos y establecer relaciones en un entorno relajado e informal. Ya sea compartiendo una comida con vista a un paisaje impresionante, participando en actividades de trabajo en equipo o explorando atracciones locales juntos, nuestras experiencias crean momentos memorables que fortalecen los vínculos y fomentan la camaradería entre nuestros clientes y sus anfitriones.",
                "Además de crear conexiones significativas, nuestras experiencias de destino personalizadas también pueden servir como plataforma para mostrar los valores, el espíritu y la cultura corporativa de su empresa. Desde prácticas sostenibles e iniciativas de participación comunitaria hasta tecnologías innovadoras y liderazgo industrial, podemos integrar estos elementos en el itinerario para mostrar su compromiso con la responsabilidad social y la sostenibilidad.",
                "Además, nuestras experiencias en destinos a medida te permiten diferenciarte de la competencia y destacar en la mente de tus clientes. Al ofrecer experiencias únicas e inolvidables que superan sus expectativas, podemos dejar una impresión duradera que lo distinga y refuerce su propuesta de valor.",
                "Para ejecutar con éxito experiencias de destino personalizadas, prestamos atención a los detalles y priorizamos la entrega perfecta de cada aspecto del itinerario. Desde el transporte y el alojamiento hasta la cena y el entretenimiento, cada elemento se planificará y ejecutará meticulosamente para garantizar una experiencia impecable para nuestros clientes.",
                "En conclusión, nuestras experiencias de destino hechas a medida ofrecen una oportunidad convincente para que usted cree momentos inolvidables y fortalezca las relaciones con sus clientes. Al personalizar el itinerario para alinearlo con sus intereses y objetivos, podemos demostrar su compromiso con el éxito de sus clientes y al mismo tiempo fomentar la lealtad y la confianza. En el competitivo panorama empresarial actual, invertir en experiencias de destinos hechas a medida no es sólo un lujo sino un imperativo estratégico para las empresas que buscan prosperar a largo plazo."
                ]
            },
            {
                img: [afrodisiasImg2],
                photoCredit: ["Arif Merter"],
                id: "MICE",
                header: "Revelando el poder de MICE: elevando los negocios a través de reuniones, incentivos, congresos y eventos",
                text: [
                "En el panorama dinámico de los negocios globales, las empresas buscan constantemente estrategias innovadoras para fomentar el crecimiento, impulsar la productividad y mejorar la colaboración. Entre estas estrategias, la industria de Reuniones, Incentivos, Congresos y Eventos (MICE) destaca como un poderoso catalizador para el éxito organizacional. Al aprovechar el potencial de las interacciones cara a cara, los incentivos estratégicos y los eventos seleccionados, las iniciativas MICE desempeñan un papel fundamental en la configuración de los resultados comerciales y el impulso del desarrollo sostenible.",
                "Más que simples reuniones",
                "La industria MICE abarca una amplia gama de actividades diseñadas para facilitar la creación de redes profesionales, el intercambio de conocimientos y el desarrollo empresarial. Desde reuniones y conferencias corporativas hasta programas de viajes de incentivo y exposiciones a gran escala, las iniciativas MICE satisfacen las necesidades multifacéticas de las empresas modernas en diversos sectores.",
                "Reuniones: En el corazón de MICE se encuentran las reuniones, que sirven como plataformas para la lluvia de ideas, la toma de decisiones y la construcción de relaciones. Ya sean reuniones de equipos a pequeña escala o grandes conferencias industriales, estos eventos brindan oportunidades invaluables para que los profesionales intercambien ideas, forjen asociaciones e impulsen los objetivos organizacionales.",
                "Incentivos: Los programas de viajes de incentivos representan otro aspecto integral del panorama MICE, diseñados para recompensar y motivar a empleados, socios y clientes. Al ofrecer experiencias de viaje únicas, los incentivos no sólo reconocen los logros individuales y de equipo, sino que también fomentan la lealtad, aumentan la moral y estimulan el desempeño dentro de las organizaciones.",
                "Congresos: Los congresos o convenciones sirven como puntos focales para el discurso de toda la industria, reuniendo a líderes de opinión, partes interesadas y expertos para abordar cuestiones clave, compartir ideas y dar forma a tendencias futuras. Estas reuniones proporcionan una plataforma para difundir conocimientos, mostrar innovaciones e impulsar el progreso colectivo dentro de sectores o comunidades profesionales específicos.",
                "Eventos: desde lanzamientos de productos y ferias comerciales hasta cenas de gala y retiros corporativos, los eventos constituyen un espectro diverso dentro del ámbito MICE. Al combinar creatividad, marca y planificación estratégica, estas ocasiones sirven como herramientas poderosas para la exposición de la marca, la participación del cliente y la diferenciación en el mercado.",
                "El impacto de MICE en el éxito empresarial",
                "La importancia de las iniciativas MICE se extiende mucho más allá de los meros arreglos logísticos o reuniones sociales. Cuando se ejecutan de manera efectiva, estas actividades tienen el potencial de generar beneficios sustanciales para las organizaciones, que incluyen:",
                "1. Redes y colaboración mejoradas: los eventos MICE brindan oportunidades incomparables para que los profesionales se conecten, colaboren y cultiven relaciones significativas. Al fomentar un entorno propicio para el diálogo y la interacción, estas reuniones facilitan el intercambio de conocimientos, las asociaciones industriales y las sinergias comerciales.",
                "2. Intercambio de conocimientos y desarrollo profesional: las conferencias, congresos y seminarios dentro del ámbito MICE sirven como plataformas valiosas para el aprendizaje, la mejora de habilidades y el desarrollo profesional. A través de discursos de apertura, paneles de discusión y talleres, los participantes obtienen información sobre las tendencias emergentes, las mejores prácticas y las soluciones innovadoras relevantes para sus campos.",
                "3. Expansión empresarial y penetración de mercado: para las empresas que buscan ampliar su alcance e ingresar a nuevos mercados, las iniciativas MICE ofrecen vías estratégicas para la investigación de mercado, la exposición de la marca y la adquisición de clientes. Al participar en ferias comerciales, exposiciones y foros comerciales, las organizaciones pueden mostrar sus productos, servicios y capacidades a una audiencia diversa de clientes y socios potenciales.",
                "4. Compromiso y motivación de los empleados: Los programas de viajes de incentivo representan una poderosa herramienta para reconocer y recompensar el desempeño de los empleados, fomentando una cultura de reconocimiento y motivación dentro de las organizaciones. Al ofrecer experiencias de viaje atractivas como incentivos, las empresas pueden incentivar el logro de objetivos, inspirar lealtad y mejorar la satisfacción de los empleados y las tasas de retención.",
                "5. Construcción de marca y gestión de la reputación: a través de eventos y congresos cuidadosamente seleccionados, las organizaciones pueden mejorar la visibilidad de su marca, su reputación y su posicionamiento en el mercado. Al alinear los temas, los mensajes y las actividades de los eventos con los valores y objetivos de su marca, las empresas pueden crear experiencias memorables que resuenen entre las partes interesadas, fortalezcan la afinidad con la marca y se diferencien en mercados competitivos.",
                "En conclusión, la industria MICE representa un componente dinámico e indispensable de la estrategia empresarial moderna, y ofrece una gran cantidad de oportunidades para que las organizaciones se conecten, colaboren y prosperen en el panorama competitivo actual. Al aprovechar el poder de las reuniones, incentivos, congresos y eventos, las empresas pueden desbloquear nuevas vías de crecimiento, innovación y éxito, impulsando el desarrollo sostenible y el impacto positivo en industrias y comunidades de todo el mundo."
                ]
            },
            {
                img: [kapadokyaImg1],
                photoCredit: ["Refik Can Apaydın"],
                id: "DMC",
                header: "Descubriendo la esencia de las empresas de gestión de destinos",
                text: [
                "En el dinámico mundo de los viajes y el turismo, donde cada viaje es una narrativa única esperando desarrollarse, el papel de las empresas de gestión de destinos (DMC) destaca como primordial. A menudo operamos detrás de escena y desempeñamos un papel crucial en la orquestación de experiencias de viaje perfectas para individuos, grupos y organizaciones por igual. Profundicemos en la esencia de las empresas de gestión de destinos y exploremos cómo damos forma al panorama de los viajes.",
                "¿Qué es una empresa de gestión de destinos?",
                "Una empresa de gestión de destinos, comúnmente conocida como DMC, es un proveedor de servicios profesionales que se especializa en la gestión y coordinación de arreglos de viaje dentro de un destino en particular. Desde logística de transporte hasta alojamiento, actividades y experiencias culturales, los DMC manejan una amplia gama de servicios diseñados para satisfacer las necesidades y deseos de los viajeros.",
                "Nuestro papel: crear experiencias inolvidables",
                "En el corazón de nuestra misión se encuentra el arte de crear experiencias inolvidables. A diferencia de las agencias de viajes tradicionales, que se centran principalmente en reservar vuelos y hoteles, ofrecemos un conjunto integral de servicios diseñados para sumergirlo en la esencia del destino elegido.",
                "1. Experiencia local: Poseemos un conocimiento íntimo de nuestros respectivos destinos. Aprovechando nuestra experiencia, seleccionamos itinerarios que muestran las joyas ocultas, los matices culturales y las experiencias auténticas únicas de cada lugar.",
                "2. Dominio logístico: desde traslados al aeropuerto hasta navegar por las bulliciosas calles de la ciudad o paisajes remotos, nos destacamos en la gestión de las complejidades logísticas de los viajes. Nuestra meticulosa planificación garantiza que cada aspecto del viaje se desarrolle sin problemas, permitiéndole concentrarse en sumergirse en la experiencia.",
                "3. Experiencias personalizadas: Reconociendo que cada viajero es único, nos destacamos en la personalización. Ya sea un retiro de lujo, una expedición llena de aventuras o un tour de inmersión cultural, adaptamos nuestras ofertas para alinearnos con las preferencias e intereses de nuestros clientes.",
                "4. Colaboración y asociaciones: cultivamos relaciones sólidas con vendedores, proveedores y proveedores de servicios locales. Estas asociaciones no sólo garantizan el acceso a experiencias exclusivas, sino que también nos permiten negociar condiciones favorables, añadiendo valor a la experiencia de viaje en general.",
                "Dando vida a los destinos",
                "- Itinerario personalizado: basándose en nuestros conocimientos locales, diseñamos un itinerario a medida que equilibra las atracciones imperdibles con experiencias fuera de lo común, garantizando una exploración holística de cada destino.",
                "- Logística sin esfuerzo: Nos encargamos de todos los aspectos del transporte, alojamiento y reservas de excursiones, eliminando el estrés asociado con la planificación de viajes. Ya sea organizando traslados de lujo u organizando visitas guiadas, cada detalle está meticulosamente coordinado.",
                "- Inmersión cultural: a través de experiencias seleccionadas, como clases de cocina con chefs locales, visitas a mercados tradicionales o interacciones con comunidades indígenas, facilitamos intercambios culturales significativos, permitiéndole obtener una comprensión más profunda de los destinos que visita.",
                "- Soporte 24 horas al día, 7 días a la semana: durante todo el viaje, brindamos soporte las 24 horas del día, lo que garantiza que cualquier circunstancia imprevista se resuelva rápidamente, mejorando así su tranquilidad general.",
                "En conclusión, desempeñamos un papel fundamental en la configuración del panorama de viajes, ofreciendo experiencia incomparable, logística fluida y experiencias seleccionadas que transforman viajes ordinarios en aventuras extraordinarias. A medida que la industria de viajes continúa evolucionando, nos adaptamos para satisfacer las necesidades y expectativas cambiantes de nuestros clientes.",
                ],
                /* burada kullanmıyorum, ancak CoverItem.js bu formatı okuyup gösteriyor...                
                itinirary: {
                    header: "Itinerario de muestra de Capadocia",
                    show: "Mostrar itinerario de muestra",
                    hide: "Cerrar itinerario de muestra",
                    dayText: "Día",
                    days: [
                        {
                            lines: [
                                { text: "Ciudad subterránea de Kaymakli" },
                                { text: "Valle de las palomas" },
                                { text: "Almorzando" },
                                { text: "Pueblo de Uçhisar" },
                                { text: "Mirador Avcılar" }
                            ]
                        },
                        {
                            lines: [
                                { text: "Museo al aire libre de Göreme: iglesias excavadas en la roca y complejo monástico" },
                                { text: "Valle del amor" },
                                { text: "Almorzando" },
                                { text: "Pueblo de Ortahisar" },
                                { text: "Valle de Dervent" }
                            ]
                        }
                    ]
                }
                */
            },
        ]
    },
    {
        id: "TR",
        title: "Kişiye Özel Destinasyon Deneyimi",
        metaDescription: "Türkiye İstanbul DMC MICE Toplantı Incentive Kongre Etkinlik Kapadokya Göbeklitepe Pamukkale Efes",
        headerLeft: "",
        headerRight: "",
        subHeader: "",
        moreText: "devamı",
        photoCreditText: "Fotoğrafı çeken",
        contact: "Lütfen özelleştirilmiş seyahat programınız ve teklifiniz için bizimle iletişime geçmekten çekinmeyin",
        cover:
        [
            {
                img: [balikligolImg1],
                photoCredit: ["Arif Merter"],
                id: "About",
                header: "Kişiye Özel Deneyimler Hakkında",
                text: [
                "Günümüzün hızlı ilerleyen kurumsal ortamında, müşterilerle anlamlı bağlantılar kurmak her zamankinden daha önemli. Şirketler, geleneksel iş toplantıları ve konferanslarının ötesinde, kurumsal müşterilerini etkilemek ve onların ilgisini çekmek için giderek daha fazla kişiye özel destinasyon deneyimlerine yöneliyor. Bu kişiye özel yolculuklar, kalıcı izlenimler yaratmak, ilişkileri güçlendirmek ve rekabetçi bir pazarda sadakati geliştirmek için eşsiz bir fırsat sunuyor.",
                "Herkese uyan tek boyutlu kurumsal etkinliklerin günleri geride kaldı. Bunun yerine şirketler, müşterilerinin özel ilgi ve tercihlerine hitap eden kişiselleştirilmiş deneyimlerin değerinin farkına varıyor. İster pitoresk bir kırsal alanda lüks bir tatil, ister tropikal bir cennette macera dolu bir gezi, ister canlı bir şehirde kültürel bir deneyim olsun, kişiye özel destinasyon deneyimleri kalıcı bir etki bırakmak üzere tasarlanmıştır.",
                "Kişiye özel destinasyon deneyimlerinin en önemli avantajlarından biri, seyahat programını müşterinin amaç ve hedefleriyle uyumlu hale getirebilme yeteneğidir. İlgi alanlarınızı, iş ihtiyaçlarınızı ve kültürel hassasiyetlerinizi anlayarak müşterilerinizde daha derin bir yankı uyandıran bir deneyim sunabiliriz. Kişiselleştirilmiş yaklaşımımız, başarınıza olan bağlılığınızı gösterir ve takdir ve güven duygusunu geliştirir.",
                "Dahası, kişiye özel destinasyon deneyimlerimiz rahat ve resmi olmayan bir ortamda ağ oluşturma ve ilişki kurma için geniş fırsatlar sunar. İster çarpıcı bir manzaraya bakan bir yemeği paylaşmak, ekip oluşturma etkinliklerine katılmak veya yerel turistik mekanları birlikte keşfetmek olsun, deneyimlerimiz, müşterilerimiz ve ev sahipleri arasındaki bağları güçlendiren ve dostluğu teşvik eden unutulmaz anlar yaratır.",
                "Anlamlı bağlantılar yaratmanın yanı sıra, kişiye özel destinasyon deneyimlerimiz aynı zamanda şirketinizin değerlerini, ahlakını ve kurum kültürünü sergilemek için bir platform görevi görebilir. Sürdürülebilir uygulamalar ve topluluk katılımı girişimlerinden yenilikçi teknolojilere ve sektör liderliğine kadar, sosyal sorumluluk ve sürdürülebilirliğe olan bağlılığınızı sergilemek için bu unsurları seyahat planına entegre edebiliriz.",
                "Ayrıca, kişiye özel destinasyon deneyimlerimiz kendinizi rakiplerinizden farklılaştırmanıza ve müşterilerinizin zihninde öne çıkmanıza olanak tanır. Beklentilerinizin üzerinde ve ötesinde benzersiz ve unutulmaz deneyimler sunarak, sizi farklı kılan ve değer önerinizi güçlendiren kalıcı bir izlenim bırakabiliriz.",
                "Kişiye özel destinasyon deneyimlerini başarılı bir şekilde gerçekleştirmek için ayrıntılara dikkat ediyor ve seyahat planının her yönünün kusursuz bir şekilde sunulmasına öncelik veriyoruz. Ulaşım ve konaklamadan yemek ve eğlenceye kadar her unsur, müşterilerimize kusursuz bir deneyim sağlamak için titizlikle planlanacak ve uygulanacaktır.",
                "Sonuç olarak, kişiye özel destinasyon deneyimlerimiz unutulmaz anlar yaratmanız ve müşterilerinizle ilişkilerinizi güçlendirmeniz için cazip bir fırsat sunuyor. Seyahat planınızı ilgi alanlarınıza ve hedeflerinize uygun olacak şekilde kişiselleştirerek, sadakat ve güveni artırırken müşterilerinizin başarısına olan bağlılığınızı gösterebiliriz. Günümüzün rekabetçi iş ortamında, kişiye özel destinasyon deneyimlerine yatırım yapmak sadece bir lüks değil aynı zamanda uzun vadede gelişmek isteyen şirketler için stratejik bir zorunluluktur."
                ]
            },
            {
                img: [afrodisiasImg2],
                photoCredit: ["Arif Merter"],
                id: "MICE",
                header: "MICE'ın Gücünü Ortaya Çıkarmak: Toplantılar, Teşvikler, Kongreler ve Etkinlikler Yoluyla İşinizi Zirveye Taşımak",
                text: [
                "Küresel ticaretin dinamik ortamında şirketler, büyümeyi teşvik etmek, üretkenliği artırmak ve işbirliğini geliştirmek için sürekli olarak yenilikçi stratejiler arıyor. Bu stratejiler arasında Toplantılar, Teşvikler, Kongreler ve Etkinlikler (MICE) sektörü, kurumsal başarı için güçlü bir katalizör olarak öne çıkıyor. MICE girişimleri, yüz yüze etkileşimlerin, stratejik teşviklerin ve özel olarak hazırlanmış etkinliklerin potansiyelinden yararlanarak iş sonuçlarını şekillendirmede ve sürdürülebilir kalkınmayı yönlendirmede önemli bir rol oynuyor.",
                "Toplantılardan Daha Fazlası",
                "MICE endüstrisi, profesyonel ağ oluşturmayı, bilgi alışverişini ve iş geliştirmeyi kolaylaştırmak için tasarlanmış çok çeşitli faaliyetleri kapsar. Kurumsal toplantılardan konferanslara, teşvik edici seyahat programlarından büyük ölçekli sergilere kadar MICE girişimleri, çeşitli sektörlerdeki modern işletmelerin çok yönlü ihtiyaçlarını karşılamaktadır.",
                "Toplantılar: MICE'ın kalbinde beyin fırtınası, karar alma ve ilişki kurma platformları olarak hizmet veren toplantılar bulunur. İster küçük ölçekli ekip toplantıları ister kapsamlı endüstri konferansları olsun, bu etkinlikler profesyonellere fikir alışverişinde bulunma, ortaklıklar kurma ve kurumsal hedefleri ileriye taşıma konusunda paha biçilmez fırsatlar sunar.",
                "Teşvikler: Teşvik edici seyahat programları, çalışanları, ortakları ve müşterileri ödüllendirmek ve motive etmek için tasarlanan MICE ortamının bir başka ayrılmaz yönünü temsil eder. Teşvikler, benzersiz seyahat deneyimleri sunarak yalnızca bireysel ve ekip başarılarını tanımakla kalmaz, aynı zamanda organizasyonlarda sadakati artırır, morali yükseltir ve performansı teşvik eder.",
                "Kongreler: Kongreler veya kongreler, sektör çapındaki söylemin odak noktaları olarak hizmet eder; düşünce liderlerini, paydaşları ve uzmanları önemli sorunları ele almak, içgörüleri paylaşmak ve gelecekteki eğilimleri şekillendirmek için bir araya getirir. Bu toplantılar bilginin yayılması, yeniliklerin sergilenmesi ve belirli sektörler veya profesyonel topluluklar içinde kolektif ilerlemenin desteklenmesi için bir platform sağlar.",
                "Etkinlikler: Ürün lansmanlarından ticaret fuarlarına, gala yemeklerinden kurumsal toplantılara kadar etkinlikler, MICE alanı içerisinde çok çeşitli bir yelpazeyi oluşturur. Yaratıcılığı, marka bilinci oluşturmayı ve stratejik planlamayı harmanlayan bu etkinlikler, markanın teşhiri, müşteri katılımı ve pazarda farklılaşma için güçlü araçlar olarak hizmet eder.",
                "MICE'ın İş Başarısına Etkisi",
                "MICE girişimlerinin önemi, yalnızca lojistik düzenlemelerin veya sosyal toplantıların çok ötesine uzanıyor. Etkin bir şekilde yürütüldüğünde, bu faaliyetlerin kuruluşlara aşağıdakiler dahil önemli faydalar sağlama potansiyeli vardır:",
                "1. Gelişmiş Ağ Oluşturma ve İşbirliği: MICE etkinlikleri, profesyonellerin bağlantı kurması, işbirliği yapması ve anlamlı ilişkiler geliştirmesi için benzersiz fırsatlar sunar. Bu toplantılar, diyalog ve etkileşim için elverişli bir ortamı teşvik ederek bilgi alışverişini, sektör ortaklıklarını ve iş sinerjilerini kolaylaştırır.",
                "2. Bilgi Paylaşımı ve Mesleki Gelişim: MICE alanındaki konferanslar, kongreler ve seminerler öğrenme, beceri geliştirme ve mesleki gelişim için değerli platformlar olarak hizmet eder. Açılış konuşmaları, panel tartışmaları ve çalıştaylar aracılığıyla katılımcılar, kendi alanlarıyla ilgili yeni ortaya çıkan trendler, en iyi uygulamalar ve yenilikçi çözümler hakkında fikir sahibi oluyor.",
                "3. İş Genişletme ve Pazara Nüfuz Etme: MICE girişimleri, erişim alanlarını genişletmek ve yeni pazarlara girmek isteyen kuruluşlar için pazar araştırması, marka görünürlüğü ve müşteri kazanımı için stratejik yollar sunar. Kuruluşlar ticari fuarlara, sergilere ve iş forumlarına katılarak ürünlerini, hizmetlerini ve yeteneklerini potansiyel müşteri ve ortaklardan oluşan çok çeşitli bir kitleye sergileyebilir.",
                "4. Çalışan Katılımı ve Motivasyonu: Teşvik seyahat programları, çalışanların performansının tanınması ve ödüllendirilmesi, kuruluşlar içerisinde takdir ve motivasyon kültürünün geliştirilmesi için güçlü bir aracı temsil eder. Şirketler, cazip seyahat deneyimlerini teşvik olarak sunarak, hedefe ulaşmayı teşvik edebilir, sadakati teşvik edebilir ve çalışan memnuniyetini ve elde tutma oranlarını artırabilir.",
                "5. Marka Oluşturma ve İtibar Yönetimi: Organizasyonlar, özenle seçilmiş etkinlikler ve kongreler aracılığıyla marka görünürlüğünü, itibarını ve pazardaki konumlarını geliştirebilirler. Şirketler, etkinlik temalarını, mesajlarını ve etkinliklerini marka değerleri ve hedefleriyle uyumlu hale getirerek, paydaşlarda yankı uyandıran, marka yakınlığını güçlendiren ve kendilerini rekabetçi pazarlarda farklılaştıran unutulmaz deneyimler yaratabilir.",
                "Sonuç olarak, MICE endüstrisi, modern iş stratejisinin dinamik ve vazgeçilmez bir bileşenini temsil etmekte ve kuruluşlara günümüzün rekabet ortamında bağlantı kurma, işbirliği yapma ve gelişme konusunda çok sayıda fırsat sunmaktadır. Şirketler toplantıların, teşviklerin, kongrelerin ve etkinliklerin gücünden yararlanarak büyüme, yenilik ve başarı için yeni yolların kilidini açabilir, sürdürülebilir kalkınmayı teşvik edebilir ve dünya çapındaki endüstriler ve topluluklar üzerinde olumlu etki yaratabilir."
                ]
            },
            {
                img: [kapadokyaImg1],
                photoCredit: ["Refik Can Apaydın"],
                id: "DMC",
                header: "Destinasyon Yönetim Şirketlerinin Özünü Ortaya Çıkarmak",
                text: [
                "Her yolculuğun ortaya çıkmayı bekleyen benzersiz bir anlatı olduğu dinamik seyahat ve turizm dünyasında, Destinasyon Yönetim Şirketlerinin (DMC'ler) rolü çok önemli olarak öne çıkıyor. Genellikle perde arkasında çalışarak bireyler, gruplar ve kuruluşlar için kusursuz seyahat deneyimlerinin düzenlenmesinde önemli bir rol oynuyoruz. Destinasyon Yönetim Şirketlerinin özünü derinlemesine inceleyelim ve seyahat ortamını nasıl şekillendirdiğimizi keşfedelim.",
                "Destinasyon Yönetim Şirketi Nedir?",
                "Genellikle DMC olarak anılan Destinasyon Yönetim Şirketi, belirli bir destinasyondaki seyahat düzenlemelerinin yönetimi ve koordinasyonunda uzmanlaşmış profesyonel bir hizmet sağlayıcıdır. DMC'ler, ulaşım lojistiğinden konaklamaya, aktivitelere ve kültürel deneyimlere kadar, gezginlerin ihtiyaç ve isteklerini karşılamak üzere tasarlanmış geniş bir hizmet yelpazesiyle ilgilenmektedir.",
                "Rolümüz: Unutulmaz Deneyimler Yaratmak",
                "Misyonumuzun kalbinde unutulmaz deneyimler yaratma sanatı yatıyor. Öncelikle uçuş ve otel rezervasyonlarına odaklanan geleneksel seyahat acentelerinin aksine, sizi seçtiğiniz destinasyonun özüne kaptırmak için tasarlanmış kapsamlı bir hizmet paketi sunuyoruz.",
                "1. Yerel Uzmanlık: İlgili destinasyonlarımız hakkında ayrıntılı bilgiye sahibiz. Uzmanlığımızdan yararlanarak, her bölgeye özgü gizli mücevherleri, kültürel nüansları ve özgün deneyimleri sergileyen güzergahlar hazırlıyoruz.",
                "2. Lojistik Ustalık: Havaalanı transferlerinden hareketli şehir sokaklarında veya uzak manzaralarda gezinmeye kadar, seyahatin lojistik karmaşıklıklarını yönetmede mükemmeliz. Titiz planlamamız, yolculuğun her yönünün kusursuz bir şekilde ortaya çıkmasını sağlayarak, deneyimin tadını çıkarmaya odaklanmanıza olanak tanır.",
                "3. Kişiye Özel Deneyimler: Her gezginin benzersiz olduğunun bilincinde olarak kişiselleştirme konusunda mükemmeliz. İster lüks bir tatil, ister macera dolu bir keşif gezisi, ister kültürel bir gezi turu olsun, tekliflerimizi müşterilerimizin tercihleri ve ilgi alanlarına göre uyarlıyoruz.",
                "4. İşbirliği ve Ortaklıklar: Yerel satıcılar, tedarikçiler ve hizmet sağlayıcılarla güçlü ilişkiler geliştiriyoruz. Bu ortaklıklar yalnızca özel deneyimlere erişim sağlamakla kalmıyor, aynı zamanda uygun koşullar üzerinde pazarlık yapmamıza olanak tanıyarak genel seyahat deneyimine değer katıyor.",
                "Destinasyonlara Hayat Vermek",
                "- Kişiselleştirilmiş Seyahat Programı: Yerel görüşlerimize dayanarak, mutlaka görülmesi gereken turistik yerleri sıra dışı deneyimlerle dengeleyen ve her varış noktasının bütünsel bir şekilde keşfedilmesini sağlayan özel bir seyahat programı tasarlıyoruz.",
                "- Zahmetsiz Lojistik: Seyahat planlamasıyla ilgili stresi ortadan kaldırarak ulaşım, konaklama ve gezi rezervasyonlarının tüm yönlerini ele alıyoruz. İster lüks transferler olsun ister rehberli turlar olsun, her ayrıntı titizlikle koordine edilmektedir.",
                "- Kültürel İçerme: Yerel şeflerle yemek pişirme dersleri, geleneksel pazarlara ziyaretler veya yerli topluluklarla etkileşimler gibi özel olarak seçilmiş deneyimler aracılığıyla, anlamlı kültürel alışverişleri kolaylaştırıyor ve ziyaret ettiğiniz destinasyonlar hakkında daha derin bir anlayış kazanmanıza olanak sağlıyoruz.",
                "- 7/24 Destek: Yolculuk boyunca 24 saat destek sağlıyoruz, öngörülemeyen durumların hızlı bir şekilde ele alınmasını sağlıyoruz ve böylece genel olarak içinizin rahat olmasını sağlıyoruz.",
                "Sonuç olarak, sıradan yolculukları olağanüstü maceralara dönüştüren benzersiz uzmanlık, kusursuz lojistik ve özenle seçilmiş deneyimler sunarak seyahat ortamını şekillendirmede çok önemli bir rol oynuyoruz. Seyahat endüstrisi gelişmeye devam ederken, biz de müşterilerimizin değişen ihtiyaç ve beklentilerini karşılamak için uyum sağlıyoruz.",
                ],
                /* burada kullanmıyorum, ancak CoverItem.js bu formatı okuyup gösteriyor...                
                itinirary: {
                    header: "Kapadokya Örnek Programı",
                    show: "Örnek Programı Göster",
                    hide: "Örnek Programı Kapat",
                    dayText: "Gün",
                    days: [
                        {
                            lines: [
                                { text: "Kaymaklı yeraltı şehri" },
                                { text: "Güvercinlik vadisi" },
                                { text: "Öğle yemeği" },
                                { text: "Uçhisar köyü" },
                                { text: "Avcılar seyir tepesi" }
                            ]
                        },
                        {
                            lines: [
                                { text: "Göreme açık hava müzesi - kayadan oyma kiliseler ve manastır kompleksi" },
                                { text: "Aşk vadisi" },
                                { text: "Öğle yemeği" },
                                { text: "Ortahisar köyü" },
                                { text: "Dervent vadisi" }
                            ]
                        }
                    ]
                }
                */
            },
        ]
    }
];

export default lList;