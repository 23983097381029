import React from 'react';
import ReactDOM from 'react-dom';

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { windowCheck } from './actions/account';

import 'bootstrap/dist/css/bootstrap.min.css';
import './index.css';
import App from './App';

const composeEnhancer = composeWithDevTools({
  // options like actionSanitizer, stateSanitizer
});

const store = createStore(
    rootReducer,
    composeEnhancer(applyMiddleware(thunk))
);

store.dispatch(windowCheck({
  id: "w",
  serialNumber: localStorage.getItem("serialNumber"),
  dateOfFirstVisit: localStorage.getItem("dateOfFirstVisit"),
  accountId: localStorage.getItem("accountId"),
  userName: localStorage.getItem("userName"),
  userSurname: localStorage.getItem("userSurname"),
  userPhone: localStorage.getItem("userPhone"),
  userEmail: localStorage.getItem("userEmail"),
  userLanguage: localStorage.getItem("userLanguage"),
  language: window.navigator.language,
  vendor: window.navigator.vendor,
  userAgent: window.navigator.userAgent,
  cookie: window.navigator.cookieEnabled
}))
  .then(() => {
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
);
});