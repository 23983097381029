import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from './actions/account';

const lList = [
    {
        id: "EN",
        text1: "It's your choice",
        text2: `We use cookies and similar technologies to bring you our expertise on destination management online. Some are necessary to help our website work properly and can't be switched off, and some are optional but support Minoras and your experience in other ways.
        For instance, we and our partners use information about you, your devices and your online interactions with us to provide, analyse and improve our services. This includes personalising content or advertising for you.
        In order to do all that;
        Cookies, device identifiers, or other information can be stored or accessed on your device.
        Ads and content can be personalised based on a profile. More data can be added to better personalise ads and content. Ad and content performance can be measured. Insights about audiences who saw the ads and content can be derived. Data can be used to build or improve user experience, systems and software.
        You can find out more in our privacy policy at the bottom of any page.`,
        text3: "Are you happy to accept cookies?",
        text4: "Accept Cookies",
        text5: "Reject Cookies"
    },
    {
        id: "DE",
        text1: "Es ist Ihre Wahl",
        text2: `Wir verwenden Cookies und ähnliche Technologien, um Ihnen unser Fachwissen zum Zielmanagement online zur Verfügung zu stellen. Einige sind für die ordnungsgemäße Funktion unserer Website erforderlich und können nicht ausgeschaltet werden, andere sind optional, unterstützen jedoch Minoras und Ihre Erfahrung auf andere Weise.
        Beispielsweise verwenden wir und unsere Partner Informationen über Sie, Ihre Geräte und Ihre Online-Interaktionen mit uns, um unsere Dienste bereitzustellen, zu analysieren und zu verbessern. Dies beinhaltet die Personalisierung von Inhalten oder Werbung für Sie.
        Um das alles zu tun;
        Cookies, Gerätekennungen oder andere Informationen können auf Ihrem Gerät gespeichert oder abgerufen werden.
        Anzeigen und Inhalte können basierend auf einem Profil personalisiert werden. Weitere Daten können hinzugefügt werden, um Anzeigen und Inhalte besser zu personalisieren. Die Leistung von Anzeigen und Inhalten kann gemessen werden. Erkenntnisse über die Zielgruppen, die die Anzeigen und Inhalte gesehen haben, können abgeleitet werden. Daten können verwendet werden, um Benutzererfahrung, Systeme und Software aufzubauen oder zu verbessern.
        Weitere Informationen finden Sie in unserer Datenschutzerklärung am Ende jeder Seite.`,
        text3: "Akzeptieren Sie gerne Cookies?",
        text4: "Cookies akzeptieren",
        text5: "Cookies ablehnen"
    },
    {
        id: "FR",
        text1: "C'est ton choix",
        text2: `Nous utilisons des cookies et technologies similaires pour vous apporter notre expertise sur la gestion des destinations en ligne. Certains sont nécessaires pour aider notre site Web à fonctionner correctement et ne peuvent pas être désactivés, et certains sont facultatifs mais prennent en charge Minoras et votre expérience d'autres manières.
        Par exemple, nous et nos partenaires utilisons des informations sur vous, vos appareils et vos interactions en ligne avec nous pour fournir, analyser et améliorer nos services. Cela inclut la personnalisation du contenu ou de la publicité pour vous.
        Afin de faire tout cela;
        Des cookies, des identifiants d'appareil ou d'autres informations peuvent être stockés ou consultés sur votre appareil.
        Les annonces et le contenu peuvent être personnalisés en fonction d'un profil. Davantage de données peuvent être ajoutées pour mieux personnaliser les annonces et le contenu. Les performances des annonces et du contenu peuvent être mesurées. Des informations sur les audiences qui ont vu les publicités et le contenu peuvent être dérivées. Les données peuvent être utilisées pour créer ou améliorer l'expérience utilisateur, les systèmes et les logiciels.
        Vous pouvez en savoir plus dans notre politique de confidentialité en bas de chaque page.`,
        text3: "Êtes-vous heureux d'accepter les cookies?",
        text4: "Accepter les cookies",
        text5: "Rejeter les cookies"
    },
    {
        id: "ES",
        text1: "Es tu elección",
        text2: `Utilizamos cookies y tecnologías similares para brindarle nuestra experiencia en la gestión de destinos en línea. Algunos son necesarios para ayudar a que nuestro sitio web funcione correctamente y no se pueden apagar, y algunos son opcionales pero ayudan a Minoras y su experiencia de otras maneras.
        Por ejemplo, nosotros y nuestros socios usamos información sobre usted, sus dispositivos y sus interacciones en línea con nosotros para proporcionar, analizar y mejorar nuestros servicios. Esto incluye personalizar el contenido o la publicidad para usted.
        Para hacer todo eso;
        Las cookies, los identificadores de dispositivos u otra información se pueden almacenar o acceder a ellos en su dispositivo.
        Los anuncios y el contenido se pueden personalizar en función de un perfil. Se pueden agregar más datos para personalizar mejor los anuncios y el contenido. Se puede medir el rendimiento de los anuncios y el contenido. Se pueden obtener conocimientos sobre las audiencias que vieron los anuncios y el contenido. Los datos se pueden utilizar para crear o mejorar la experiencia del usuario, los sistemas y el software.
        Puede obtener más información en nuestra política de privacidad en la parte inferior de cualquier página.`,
        text3: "¿Estás contenta/o de aceptar cookies?",
        text4: "Acepto cookies",
        text5: "Rechazar cookies"
    },
    {
        id: "TR",
        text1: "Bu senin seçimin",
        text2: `Size çevrimiçi destinasyon yönetimi konusundaki uzmanlığımızı sunmak için tanımlama bilgileri ve benzer teknolojiler kullanıyoruz. Bazıları web sitemizin düzgün çalışmasına yardımcı olmak için gereklidir ve kapatılamaz ve bazıları isteğe bağlıdır ancak Minoras'ı ve deneyiminizi başka şekillerde destekler.
        Örneğin, biz ve ortaklarımız siz, cihazlarınız ve bizimle çevrimiçi etkileşimleriniz hakkındaki bilgileri hizmetlerimizi sağlamak, analiz etmek ve iyileştirmek için kullanırız. Buna içeriğin kişiselleştirilmesi veya sizin için reklamlar dahildir.
        Tüm bunları yapabilmek için;
        Çerezler, cihaz tanımlayıcıları veya diğer bilgiler cihazınızda saklanabilir veya cihazınıza erişilebilir.
        Reklamlar ve içerik bir profile göre kişiselleştirilebilir. Reklamları ve içeriği daha iyi kişiselleştirmek için daha fazla veri eklenebilir. Reklam ve içerik performansı ölçülebilir. Reklamları ve içeriği gören kitleler hakkında öngörüler elde edilebilir. Veriler, kullanıcı deneyimini, sistemleri ve yazılımları oluşturmak veya geliştirmek için kullanılabilir.
        Daha fazla bilgiyi herhangi bir sayfanın altındaki gizlilik politikamızda bulabilirsiniz.`,
        text3: "Çerezleri kabul etmek ister misiniz?",
        text4: "Çerezleri kabul et",
        text5: "Çerezleri reddet"
    }
];

class Cookies extends Component {
    componentDidMount() {
        const text = `Cookies: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
    }

    handleClick = (pg) => {
        let text = "";
        switch(pg) {
            case 'accept':
                text = `Cookies: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, Cookies:Accepted`;
                localStorage.setItem("cookiesAccepted", "Y");
                this.props.changeVisible(false);
                break;
            default:
            case 'reject':
                text = `Cookies: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, Cookies:Rejected`;
                localStorage.setItem("cookiesAccepted", "N");
                this.props.changeVisible(false); // ileride true olacak eğer girmesi istenmiyorsa..
                break;
        }
        this.props.invokeLogger({ id: "w", text });
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div className="Cookies">
                <div className="CookiesHeader">
                    {lItem.text1}
                </div>
                <div className="CookiesText">
                    {lItem.text2}
                </div>
                <div className="CookiesHeader">
                    {lItem.text3}
                </div>
                <div className="CookiesButton" onClick={() => this.handleClick('accept')}>
                    {lItem.text4}
                </div>
                <div className="CookiesButton" onClick={() => this.handleClick('reject')}>
                    {lItem.text5}
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Cookies);