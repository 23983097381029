import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from './actions/account';

class CoverItem extends Component {
    state = {
        defaultBorderColor: 'BorderDefaultColor',
        pictureIndex: 0,
        showText: false,
        showItinirary: false
    }

    handleTextClick = () => {
        this.setState({ showText: !this.state.showText });

        const text = `Cover: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, Text ${this.state.showText ? 'hidden' : 'shown'} for ${this.props.id}`;
        this.props.invokeLogger({ id: "w", text });
    }

    handlePictureClick = () => {
        let i = this.state.pictureIndex + 1;
        if (i >= this.props.image.length)
            i = 0;
        this.setState({ pictureIndex: i });

        const text = `Cover: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, Pix ${this.props.id}:${i+1} is viewed`;
        this.props.invokeLogger({ id: "w", text });
    }

    handlePictureIndexClick = (index) => {
        this.setState({ pictureIndex: index });

        const text = `Cover: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, Pix ${this.props.id}:${index+1} is viewed`;
        this.props.invokeLogger({ id: "w", text });
    }

    handleMouseEnter = () => {
        this.setState({ defaultBorderColor: 'BorderHighlightColor' });
    }

    handleMouseLeave = () => {
        this.setState({ defaultBorderColor: 'BorderDefaultColor' });
    }

    handleDragStart = (e) => {
        e.preventDefault();

        const text = `Cover: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, Drag ${this.props.id}:${this.state.pictureIndex+1} is tried`;
        this.props.invokeLogger({ id: "w", text });
    }

    handleItinirary = () => {
        this.setState({ showItinirary: !this.state.showItinirary });

        const text = `Cover: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, Itinirary ${this.state.showItinirary ? 'hidden' : 'shown'} for ${this.props.id}`;
        this.props.invokeLogger({ id: "w", text });
    }

    render() {
        return (
            <div className="Cover">
                <div
                    className={"CoverItem " + this.state.defaultBorderColor}
                    onDragStart={this.handleDragStart}
                    onContextMenu={this.handleDragStart}
                    onMouseEnter={this.handleMouseEnter}
                    onMouseLeave={this.handleMouseLeave}> 
                    <div
                        className="CoverItemImage"
                        onClick={this.handlePictureClick}>
                        {
                            this.props.image[this.state.pictureIndex] !== "" ?
                            <div>
                                <img src={this.props.image[this.state.pictureIndex]} alt="Minoras" />
                                <div className="CoverItemPhotoCredit">
                                    {this.props.photoCreditText} {this.props.photoCredit[this.state.pictureIndex]}
                                </div>
                            </div>
                            : null
                        }
                    </div>
                    {
                        this.props.image.length > 1 ?
                        <div className="CoverPictureTab">
                            {
                                this.props.image.map((pic,i) => (
                                    <div
                                        className={ this.state.pictureIndex === i ?
                                        "CoverPictureCircleFilled" : "CoverPictureCircle"}
                                        onClick={() => this.handlePictureIndexClick(i)}
                                        key={i}>
                                    </div>
                                ))
                            }
                        </div> : null
                    }
                    <div className="CoverItemHeader" onClick={this.handleTextClick}>
                        {this.props.header}
                        {
                            this.state.showText ? 
                                <div className="CoverItemText">
                                    {
                                        this.props.text.map((textN,n) => (
                                            <p key={n}>{textN}</p>
                                        ))
                                    }
                                </div>
                            :
                                <div className="CoverItemText SingleLine">
                                    {this.props.text}
                                </div>
                        }
                    </div>
                    {
                        this.state.showText ? null :
                        <div className="CoverItemMoreText" onClick={this.handleTextClick}>
                            {this.props.moreText}...
                        </div>
                    }
                    {
                        this.state.showItinirary ? 
                            <div className="CoverItemItinirary" onClick={() => this.handleItinirary(this.props.itinirary)}>
                                <div className="CoverItemItiniraryHeader">
                                    {this.props.itinirary.header}
                                </div>
                                {
                                    this.props.itinirary.days.map((dayItem,i) => (
                                    <table className="CoverItemTable" key={i}>
                                        <tbody>
                                        <tr className="CoverItemTableRowHeader">
                                            <th>
                                                {this.props.itinirary.dayText} {i+1}
                                            </th>
                                        </tr>
                                        {
                                            dayItem.lines.map((line,j) => (
                                            <tr className="CoverItemTableRow" key={j}>
                                                <td>
                                                {line.text}
                                                </td>
                                            </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                    ))
                                }
                                <div>
                                    {this.props.contact}
                                </div>
                            </div>
                        : null
                    }
                    {
                        this.props.itinirary !== undefined ?
                            <div className="CoverItemItiniraryIndicator" onClick={() => this.handleItinirary(this.props.itinirary)}>
                                {this.state.showItinirary ? this.props.itinirary.hide
                                :
                                this.props.itinirary.show}...
                            </div> : null
                    }
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(CoverItem);