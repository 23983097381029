import React, { Component } from 'react';
import InputGroup from 'react-bootstrap/InputGroup';
import FormControl from 'react-bootstrap/FormControl';
import { Link } from 'react-router-dom';

const lList = [
    {
        id: "EN",
        header: "Contact Form",
        text1: "Name",
        text2: "Enter name",
        text3: "Surname",
        text4: "Enter surname",
        text5: "Phone",
        text6: "Enter phone number",
        text7: "E-Mail",
        text8: "Enter E-Mail",
        text9: "Subject",
        text10: "Choose a subject",
        value11: "Quotation",
        text11: "I want to get a quotation",
        value12: "Information",
        text12: "I want to get information",
        value13: "Comment",
        text13: "I want to make a comment",
        value14: "Complaint",
        text14: "I want to make a complaint",
        value15: "Other",
        text15: "Other",
        text16: "Message",
        text17: "Enter message",
        text18: "Send",
        text19: "Please Fill in the form"
    },
    {
        id: "DE",
        header: "Kontakt Formular",
        text1: "Name",
        text2: "Name eingeben",
        text3: "Familienname",
        text4: "Familienname eingeben",
        text5: "Telefon",
        text6: "Telefon eingeben",
        text7: "E-Mail",
        text8: "E-Mail eingeben",
        text9: "Betreff",
        text10: "Wählen Sie ein Betreff",
        value11: "Angebot",
        text11: "Ich möchte ein Angebot einholen",
        value12: "Information",
        text12: "Ich möchte Informationen erhalten",
        value13: "Kommentar",
        text13: "Ich möchte einen Kommentar abgeben",
        value14: "Beschwerde",
        text14: "Ich möchte eine Beschwerde einreichen",
        value15: "Anderes",
        text15: "Anderes",
        text16: "Nachricht",
        text17: "Nachricht eingeben",
        text18: "Senden",
        text19: "Bitte füllen Sie das Formular aus"
    },
    {
        id: "FR",
        header: "Formulaire de contact",
        text1: "Nom",
        text2: "Entrez le nom",
        text3: "Nom de famille",
        text4: "Entrez le nom de famille",
        text5: "Téléphoner",
        text6: "Entrez le numéro de téléphone",
        text7: "E-Mail",
        text8: "Entrez l'e-mail",
        text9: "Sujet",
        text10: "Entrez le sujet",
        value11: "Devis",
        text11: "Je veux obtenir un devis",
        value12: "Information",
        text12: "Je veux avoir des informations",
        value13: "Commenter",
        text13: "Je veux faire un commentaire",
        value14: "Plainte",
        text14: "Je veux porter plainte",
        value15: "Autre",
        text15: "Autre",
        text16: "Message",
        text17: "Entrer un message",
        text18: "Envoyer",
        text19: "Veuillez remplir le formulaire"
    },
    {
        id: "ES",
        header: "Formulario de contacto",
        text1: "Nombre",
        text2: "Ingrese su nombre",
        text3: "Apellido",
        text4: "Introduce el apellido",
        text5: "Teléfono",
        text6: "Introduzca el número de teléfono",
        text7: "Correo electrónico",
        text8: "Ingrese correo electrónico",
        text9: "Tema",
        text10: "Elige un tema",
        value11: "Cotización",
        text11: "quiero obtener una cotización",
        value12: "Información",
        text12: "Quiero obtener informacion",
        value13: "Comentario",
        text13: "Quiero hacer un comentario",
        value14: "Queja",
        text14: "Quiero hacer una denuncia",
        value15: "Otro",
        text15: "Otro",
        text16: "Mensaje",
        text17: "Introducir mensaje",
        text18: "Enviar",
        text19: "Por favor rellene el formulario"
    },
    {
        id: "TR",
        header: "İletişim Formu",
        text1: "Ad",
        text2: "Adınızı giriniz",
        text3: "Soyad",
        text4: "Soyadınızı giriniz",
        text5: "Telefon",
        text6: "Telefon numaranızı giriniz",
        text7: "E-Posta",
        text8: "E-Posta adresinizi giriniz",
        text9: "Konu",
        text10: "Konu seçiniz",
        value11: "Fiyat",
        text11: "Fiyat almak istiyorum",
        value12: "Bilgi",
        text12: "Bilgi almak istiyorum",
        value13: "Yorum",
        text13: "Yorum yapmak istiyorum",
        value14: "Şikayet",
        text14: "Şikayet bildirmek istiyorum",
        value15: "Diğer",
        text15: "Diğer",
        text16: "Mesaj",
        text17: "Mesajınızı giriniz",
        text18: "Gönder",
        text19: "Lütfen Formu doldurunuz"
    }
];

class ContactForm extends Component {
    state = {
        konu: '',
        ad: '',
        soyad: '',
        phone: '',
        email: '',
        mesaj: ''
    }

    componentDidUpdate = () => {
        if (this.props.contactFormSent) {
            this.props.changeContactFormSent(false);
            this.setState({
                konu: '',
                ad: '',
                soyad: '',
                phone: '',
                email: '',
                mesaj: ''
            });
        }
    }

    handleChange = event => {
        this.setState({ [event.target.name]: event.target.value });
    }

    checkValidity = () => {
        let { ad, soyad, phone, email, konu, mesaj } = this.state;

        if ((ad !== '' || soyad !== '') &&
            (phone !== '' || email !== '') &&
            (konu !== '' || mesaj !== ''))
            return true;

        return false;
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div className="ContactForm">
                <div className="ContactHeader">
                    {lItem.header}
                </div>
                <InputGroup className="ContactField">
                    <InputGroup.Prepend>
                    <InputGroup.Text style={{ "width" : '150px' }}>{lItem.text1}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        name="ad"
                        value={this.state.ad}
                        onChange={this.handleChange}
                        placeholder={lItem.text2}
                    />
                </InputGroup>
                <InputGroup className="ContactField">
                    <InputGroup.Prepend>
                    <InputGroup.Text style={{ "width" : '150px' }}>{lItem.text3}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        name="soyad"
                        value={this.state.soyad}
                        onChange={this.handleChange}
                        placeholder={lItem.text4}
                    />
                </InputGroup>
                <InputGroup className="ContactField">
                    <InputGroup.Prepend>
                    <InputGroup.Text style={{ "width" : '150px' }}>{lItem.text5}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        name="phone"
                        value={this.state.phone}
                        onChange={this.handleChange}
                        placeholder={lItem.text6}
                    />
                </InputGroup>
                <InputGroup className="ContactField">
                    <InputGroup.Prepend>
                    <InputGroup.Text style={{ "width" : '150px' }}>{lItem.text7}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        name="email"
                        value={this.state.email}
                        onChange={this.handleChange}
                        placeholder={lItem.text8}
                    />
                </InputGroup>
                <InputGroup className="ContactField">
                    <InputGroup.Prepend>
                    <InputGroup.Text style={{ "width" : '150px' }}>{lItem.text9}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        as="select"
                        value={this.state.konu}
                        onChange={this.handleChange}
                        name='konu'
                    >
                        <option key='XX' value='XX'>{lItem.text10}</option>
                        <option key='FY' value={lItem.value11}>{lItem.text11}</option>
                        <option key='BL' value={lItem.value12}>{lItem.text12}</option>
                        <option key='YR' value={lItem.value13}>{lItem.text13}</option>
                        <option key='SK' value={lItem.value14}>{lItem.text14}</option>
                        <option key='DG' value={lItem.value15}>{lItem.text15}</option>
                    </FormControl>
                </InputGroup>
                <InputGroup className="ContactField">
                    <InputGroup.Prepend>
                    <InputGroup.Text style={{ "width" : '150px' }}>{lItem.text16}</InputGroup.Text>
                    </InputGroup.Prepend>
                    <FormControl
                        name="mesaj"
                        as="textarea"
                        rows={5}
                        value={this.state.mesaj}
                        onChange={this.handleChange}
                        placeholder={lItem.text17}
                    />
                </InputGroup>
                <div className="ContactButton">
                    {
                        this.checkValidity() ? 
                        <Link
                            className="MyButton btn-secondary"
                            to={`/iletisim-formu-alindi?ileten=Z&ad=${this.state.ad}&soyad=${this.state.soyad}&tel=${this.state.phone}&email=${this.state.email}&konu=${this.state.konu}&mesaj=${this.state.mesaj}`}>
                            {lItem.text18}
                        </Link> :
                        <Link
                            className="MyButton btn-secondary"
                            to="#">
                            {lItem.text19}
                        </Link>
                    }
                </div>
            </div>
        )        
    }
}

export default ContactForm;