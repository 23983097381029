import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from './actions/account';
import { Redirect } from 'react-router-dom';
import Header from './Header';
import CoverItem from './CoverItem';
import MetaTags from 'react-meta-tags';

import lList from './GezilecekYerler.js';

class AnaSayfa extends Component {
    state = {
        redirect: 'none'
    }

    componentDidMount() {
        const text = `Home: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    callPage = (redirect) => {
        this.setState({ redirect });
    }

    render() { 
        let lItem = lList.find((item) => { return item.id === this.props.language });

        if (this.state.redirect === 'none') {
            return (
                <div>
                    <MetaTags>
                        <title>Minoras | {lItem.title}</title>
                        <meta name="description" content={lItem.metaDescription} />
                    </MetaTags>

                    <Header language={this.props.language} />
                                        
                    <div className="PageHeader">
                        {lItem.headerLeft}
                        <span className="PageHeaderAlt"> {lItem.headerRight}</span>
                        <div className="PageSubHeader">
                            {lItem.subHeader}    
                        </div>
                    </div>

                    <div className="Anasayfa">
                    {
                        lItem.cover.map((item,i) => (
                            <div className="Cover" key={item.id}>
                                <CoverItem
                                    id={item.id}
                                    header={item.header}
                                    text={item.text}
                                    image={item.img}
                                    photoCreditText={lItem.photoCreditText}
                                    photoCredit={item.photoCredit}
                                    itinirary={item.itinirary}
                                    contact={lItem.contact}
                                    redirect=""
                                    callPage={this.callPage}
                                    moreText={lItem.moreText}
                                />
                            </div>
                        ))
                    }
                    </div>
                </div>
            );
        }
        return (<Redirect push to={this.state.redirect} />);
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(AnaSayfa);
