const ACCOUNT = {
    INVOKELOGGER: 'ACCOUNT_INVOKELOGGER',
    INVOKELOGGER_ERROR: 'ACCOUNT_INVOKELOGGER_ERROR',
    INVOKELOGGER_SUCCESS: 'ACCOUNT_INVOKELOGGER_SUCCESS',
    SENDCONTACTFORM: 'ACCOUNT_SENDCONTACTFORM',
    SENDCONTACTFORM_ERROR: 'ACCOUNT_SENDCONTACTFORM_ERROR',
    SENDCONTACTFORM_SUCCESS: 'ACCOUNT_SENDCONTACTFORM_SUCCESS',
    WINDOWCHECK: 'ACCOUNT_WINDOWCHECK',
    WINDOWCHECK_ERROR: 'ACCOUNT_WINDOWCHECK_ERROR',
    WINDOWCHECK_SUCCESS: 'ACCOUNT_WINDOWCHECK_SUCCESS'
};

export { ACCOUNT };