import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const lList = [
    {
        id: "EN",
        text1: "all rights reserved",
        text2: "Contact",
        text3: "Privacy"
    },
    {
        id: "DE",
        text1: "Alle Rechte vorbehalten",
        text2: "Kontakt",
        text3: "Datenschutz-Bestimmungen"
    },
    {
        id: "FR",
        text1: "tous les droits sont réservés",
        text2: "Contact",
        text3: "Politique de confidentialité"
    },
    {
        id: "ES",
        text1: "reservados todos los derechos",
        text2: "Contacto",
        text3: "Política de privacidad"
    },
    {
        id: "TR",
        text1: "tüm hakları saklıdır",
        text2: "İletişim",
        text3: "Gizlilik"
    }
];

class Tail extends Component {
    render () {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div className="Tail">
                <div className="TailReserved">
                © 2014-{(new Date().getFullYear())} <span className="TailReservedLight">Minoras</span> {lItem.text1}
                </div>
                <div className="TailMenu">
                    <span>
                        <Link className="TailMenuItem" to="/iletisim">{lItem.text2}</Link>
                        <Link className="TailMenuItem" to="/gizlilik-politikasi">{lItem.text3}</Link>
                    </span>
                </div>
            </div>
        )
    }
}

export default Tail;