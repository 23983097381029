import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from './actions/account';
import HeaderSub from './HeaderSub';
import MetaTags from 'react-meta-tags';

const lList = [
    {
        id: "EN",
        header: "Privacy Policy",
        text1: "Küçük Asya Turizm A.Ş, which will be referred to as Minoras from now on, has adopted the principle of protecting the privacy of visitors to its website.",
        text2: "In this context, as Minoras, we comply with the provisions of the Personal Data Protection Law No. 6698 (“Law”), the decisions of the Personal Data Protection Board (“Board”) and, as appropriate, the provisions of the European Union Data Protection Regulation (“GDPR”) in order to process personal data in accordance with the law.",
        text3: "We would like to inform you about what kind of information about users is processed by Minoras and how it is used.",
        text4: "Possible changes to this Privacy and Cookie Policy will be published on this page. In this framework, you will be able to receive up-to-date information on what information is stored and how this information is used.",
        text5: "This Privacy and Cookie Policy does not apply to other websites that have a link extension from the Minoras site.",
        text6: "Collection, Use and Processing of Personal Data",
        text7: "Name of the responsible institution: Küçük Asya Turizm A.Ş.",
        text8: "Address of the responsible institution: Esentepe Mah. Koral Han Ap. No: 40/A Şişli - İstanbul",
        text9: "Minoras uses the relevant personal data in order to present this website to users and to ensure that this website functions properly and that the necessary security is provided. Every transaction outside this scope and performed on the data is carried out on the basis of other legal obligations, permissions, situations where Minoras has a legitimate interest or within the framework of the express consent given to Minoras by the relevant user.",
        text10: "Minoras stores and processes the relevant data specifically for the following purposes:",
        text11: "Minoras automatically collects and stores certain data when users visit the website. This data includes: IP address or device ID allocated to the relevant end device, required to transmit the requested content (e.g. especially content, texts, images and data files provided for download, etc.), users' activities in the context of the website, relevant end device type, the type of browser used, and the date and time of use.",
        text12: "Minoras keeps this information in order to detect and monitor any misuses.",
        text13: "At the same time, Minoras uses this information to improve the delivery of services, their features, functions and general administrative tasks.",
        text14: "In addition, Minoras deletes or anonymizes data in use, including IP addresses, without undue delay, when such data is no longer needed for the purposes given above.",
        text15: "The processing and use of the relevant data means that (1) the processing is necessary for the website to be offered; or (2) In cases where Minoras has a legitimate interest in ensuring and improving the functionality and smooth operation of the website, as well as ensuring that it is suitable for users' needs, it is carried out on the basis of the relevant legal provisions that allow these transactions to be carried out.",
        text16: "To the recipients of the collected data; members of the internal departments responsible, other companies affiliated with Minoras, external service providers (eg hosting and content management, marketing agencies, other third party providers if necessary due to the services they provide), within the framework of their respective obligations and powers; for example, relevant authorities and current shareholders of Minoras companies or future shareholders in the event of a sale, merger or acquisition, if a formal request is made or necessary to establish, exercise or defend the rights that Minoras has.",
        text17: "Purposes of Processing Personal Data",
        text18: "Contact / Customer Request Form",
        text19: "You may have shared your contact information with us through the contact form on our website or through our call center numbers on the relevant tab.",
        text20: "We will use your data to respond to your request.",
        text21: "The processing and use of your data is carried out on the basis of the legal provisions and/or Minoras' legitimate interest, enabling this to be done if the processing is necessary to meet your current request.",
        text22: "Unless you have given your consent for a longer period of time through any express consent, we will only retain the data for the period necessary to achieve the above objective or any legal retention requirement.",
        text23: "Cookies, Pixels and Similar Technologies",
        text24: "This website uses cookies and similar technologies. Cookies are small units of data that are required to use our website and are temporarily stored on your hard drive by your browser. A cookie usually contains a unique identifier, which is a randomly generated number and is stored on your device. Some of these identifiers expire when your website session ends, while others may remain on your device for longer periods of time. Technically necessary cookies will be used automatically. Other cookies (or similar technologies) will only be applied upon your prior consent.",
        text25: "There are different types of cookies. First-party cookies are placed by the website visited and can only be read by that website. Third-party cookies, on the other hand, are placed by other organizations from which we receive services for various services we use. As an example, we use external analytics service providers that place cookies on our behalf in order to identify liked or disliked content on our website. In addition, the website you are visiting may contain embedded content from a source such as YouTube, for example, and cookies may be placed by that source as well.",
        text26: "A pixel is usually only a 1 pixel x 1 pixel transparent image that is placed on a website for the purpose of personalizing content or making the browser experience more effective and convenient, and collects certain data such as IP address, browser type, access time or preset cookies. They are often used in conjunction with cookies because the pixels create and manage cookies.",
        text27: "The website will collect the device's operating system, browser, installed fonts, IP address, plug-ins and more detailed information if you give your consent. In this way, the device can be re-identified for marketing purposes for the user.",
        text28: "You can take a look at the Cookies and Cookie settings sections on our website to get more detailed information about cookies and other technologies used on our website and their purposes. At the same time, you can withdraw your existing consents with effect from now on by disabling the relevant cookie settings from within these sections.",
        text29: "Mapp Intelligence (former Webtrekk)",
        text30: "Google Ads",
        text31: "Google Campaign Manager 360 (former DoubleClick)",
        text32: "Social Media",
        text33: "Your Rights as Data Owner",
        text34: "You can always exercise your right to access your data. In addition, if the relevant conditions are met, you can use your rights in Article 11 of the Law and the following rights:",
        text35: "Right to correct",
        text36: "Right to delete",
        text37: "Right to restrict processing",
        text38: "Right to raise a complaint through the competent data protection supervisory authority",
        text39: "Right to data portability (as of 25 May 2018)",
        text40: "Right to object",
        text41: "When it comes to the activities related to the processing of your personal data on the basis that Minoras has a legal benefit, you always have the right to object to the processing of your personal data due to the reasons arising from the special situation you are in. Minoras will stop the processing of data unless Minoras can prove that there is an important justification for the processing that needs to be protected and that override your interests, rights and freedoms, or unless this process serves purposes such as making, exercising or defending legal claims.",
        text42: "If you give consent for the processing of your personal data, you can withdraw your consent with effect from now on.",
        text43: "Within the scope of the law, you can send all kinds of requests, complaints and suggestions regarding your personal data to the address below, with documents proving your identity and your petition containing your request, or to our company's registered e-mail address kucukasyaturizm@hs01.kep.tr by using a secure electronic signature.",
        text44: "Address: Küçük Asya Turizm A.Ş., Esentepe Mah. Koral Han Ap. No: 40/A Şişli İstanbul Turkey"
    },
    {
        id: "DE",
        header: "Datenschutz-Bestimmungen",
        text1: "Küçük Asya Turizm A.Ş, die fortan als Minoras bezeichnet wird, hat sich den Grundsatz des Schutzes der Privatsphäre der Besucher ihrer Website zu eigen gemacht.",
        text2: "In diesem Zusammenhang halten wir uns als Minoras an die Bestimmungen des Datenschutzgesetzes Nr. 6698 („Gesetz“), die Entscheidungen des Datenschutzausschusses („Vorstand“) und gegebenenfalls die Bestimmungen des Europäischen Unions-Datenschutz-Grundverordnung („DSGVO“), um personenbezogene Daten im Einklang mit dem Gesetz zu verarbeiten.",
        text3: "Wir möchten Sie darüber informieren, welche Art von Informationen über Nutzer von Minoras verarbeitet und wie diese verwendet werden.",
        text4: "Mögliche Änderungen dieser Datenschutz- und Cookie-Richtlinie werden auf dieser Seite veröffentlicht. In diesem Rahmen erhalten Sie aktuelle Informationen darüber, welche Informationen gespeichert und wie diese verwendet werden.",
        text5: "Diese Datenschutz- und Cookie-Richtlinie gilt nicht für andere Websites, die über eine Linkerweiterung der Minoras-Site verfügen.",
        text6: "Erhebung, Nutzung und Verarbeitung personenbezogener Daten",
        text7: "Name der zuständigen Institution: Küçük Asya Turizm A.Ş.",
        text8: "Anschrift der zuständigen Institution: Esentepe Mah. Koral Han Ap. Nr: 40/A Sisli - Istanbul",
        text9: "Minoras verwendet die entsprechenden personenbezogenen Daten, um den Nutzern diese Website zu präsentieren, die Funktionsfähigkeit dieser Website sowie die erforderliche Sicherheit zu gewährleisten. Jede Transaktion außerhalb dieses Rahmens und die mit den Daten durchgeführt wird, erfolgt aufgrund sonstiger gesetzlicher Verpflichtungen, Erlaubnisse, Situationen, in denen Minoras ein berechtigtes Interesse hat oder im Rahmen einer ausdrücklichen Einwilligung des jeweiligen Nutzers gegenüber Minoras.",
        text10: "Minoras speichert und verarbeitet die entsprechenden Daten insbesondere zu folgenden Zwecken:",
        text11: "Minoras erhebt und speichert automatisch bestimmte Daten, wenn Benutzer die Website besuchen. Zu diesen Daten gehören: dem jeweiligen Endgerät zugeordnete IP-Adresse oder Geräte-ID, die zur Übermittlung der angeforderten Inhalte (zB zum Download bereitgestellte Inhalte, Texte, Bilder und Dateien etc.) erforderlich sind, Aktivitäten der Nutzer im Rahmen der Website , der jeweilige Endgerätetyp, der verwendete Browsertyp sowie das Datum und die Uhrzeit der Nutzung.",
        text12: "Minoras speichert diese Informationen, um Missbrauch aufzudecken und zu überwachen.",
        text13: "Gleichzeitig verwendet Minoras diese Informationen, um die Bereitstellung von Diensten, deren Eigenschaften, Funktionen und allgemeine Verwaltungsaufgaben zu verbessern.",
        text14: "Darüber hinaus löscht oder anonymisiert Minoras Nutzungsdaten, einschließlich IP-Adressen, unverzüglich, wenn diese Daten für die oben genannten Zwecke nicht mehr benötigt werden.",
        text15: "Die Verarbeitung und Nutzung der entsprechenden Daten hat zur Folge, dass (1) die Verarbeitung zur Bereitstellung der Website erforderlich ist; oder (2) In Fällen, in denen Minoras ein berechtigtes Interesse an der Gewährleistung und Verbesserung der Funktionsfähigkeit und des reibungslosen Betriebs der Website sowie deren Anpassung an die Bedürfnisse der Nutzer hat, erfolgt dies auf Grundlage der einschlägigen gesetzlichen Bestimmungen die die Durchführung dieser Transaktionen ermöglichen.",
        text16: "An die Empfänger der erhobenen Daten; Mitglieder der zuständigen internen Abteilungen, sonstige mit Minoras verbundene Unternehmen, externe Dienstleister (zB Hosting und Content Management, Marketingagenturen, sonstige Drittanbieter, soweit dies aufgrund der von ihnen erbrachten Leistungen erforderlich ist) im Rahmen ihrer jeweiligen Pflichten und Befugnisse; beispielsweise zuständige Behörden und derzeitige Aktionäre von Minoras-Unternehmen oder zukünftige Aktionäre im Falle eines Verkaufs, einer Fusion oder eines Erwerbs, wenn ein formeller Antrag gestellt wird oder erforderlich ist, um die Rechte von Minoras zu begründen, auszuüben oder zu verteidigen.",
        text17: "Zwecke der Verarbeitung personenbezogener Daten",
        text18: "Kontakt / Kundenanfrageformular",
        text19: "Möglicherweise haben Sie uns Ihre Kontaktinformationen über das Kontaktformular auf unserer Website oder über unsere Callcenter-Nummern auf der entsprechenden Registerkarte mitgeteilt.",
        text20: "Wir verwenden Ihre Daten, um Ihre Anfrage zu beantworten.",
        text21: "Die Verarbeitung und Nutzung Ihrer Daten erfolgt auf Grundlage der gesetzlichen Bestimmungen und/oder des berechtigten Interesses von Minoras, so dass dies erfolgen kann, wenn die Verarbeitung zur Erfüllung Ihres aktuellen Anliegens erforderlich ist.",
        text22: "Sofern Sie nicht durch eine ausdrückliche Einwilligung für einen längeren Zeitraum eingewilligt haben, speichern wir die Daten nur für den Zeitraum, der zur Erreichung des oben genannten Zwecks oder einer gesetzlichen Aufbewahrungspflicht erforderlich ist.",
        text23: "Cookies, Pixel und ähnliche Technologien",
        text24: "Diese Website verwendet Cookies und ähnliche Technologien. Cookies sind kleine Dateneinheiten, die für die Nutzung unserer Website erforderlich sind und die von Ihrem Browser temporär auf Ihrer Festplatte gespeichert werden. Ein Cookie enthält normalerweise eine eindeutige Kennung, die eine zufällig generierte Zahl ist und auf Ihrem Gerät gespeichert wird. Einige dieser Kennungen laufen ab, wenn Ihre Website-Sitzung endet, während andere für längere Zeit auf Ihrem Gerät verbleiben können. Technisch notwendige Cookies werden automatisch verwendet. Andere Cookies (oder ähnliche Technologien) werden nur nach Ihrer vorherigen Zustimmung verwendet.",
        text25: "Es gibt verschiedene Arten von Cookies. Erstanbieter-Cookies werden von der besuchten Website platziert und können nur von dieser Website gelesen werden. Cookies von Drittanbietern hingegen werden von anderen Organisationen platziert, von denen wir Dienste für verschiedene von uns genutzte Dienste erhalten. Als Beispiel verwenden wir externe Analysedienstleister, die in unserem Auftrag Cookies platzieren, um Inhalte auf unserer Website zu identifizieren, die gefallen oder nicht gefallen haben. Darüber hinaus kann die von Ihnen besuchte Website eingebettete Inhalte aus einer Quelle wie beispielsweise YouTube enthalten, und auch von dieser Quelle können Cookies platziert werden.",
        text26: "Ein Pixel ist in der Regel nur ein 1 Pixel x 1 Pixel transparentes Bild, das auf einer Website platziert wird, um Inhalte zu personalisieren oder das Browser-Erlebnis effektiver und komfortabler zu gestalten und bestimmte Daten wie IP-Adresse, Browsertyp, Zugriffszeit oder voreingestellte Cookies. Sie werden häufig in Verbindung mit Cookies verwendet, da die Pixel Cookies erstellen und verwalten.",
        text27: "Die Website erhebt das Betriebssystem des Geräts, den Browser, installierte Schriftarten, IP-Adresse, Plug-Ins und weitere Informationen, wenn Sie Ihre Einwilligung erteilen. Auf diese Weise kann das Gerät zu Marketingzwecken für den Nutzer wiedererkannt werden.",
        text28: "Sie können einen Blick auf die Abschnitte Cookies und Cookie-Einstellungen auf unserer Website werfen, um detailliertere Informationen zu Cookies und anderen auf unserer Website verwendeten Technologien und deren Zwecken zu erhalten. Gleichzeitig können Sie Ihre bestehenden Einwilligungen mit Wirkung ab sofort widerrufen, indem Sie die entsprechenden Cookie-Einstellungen in diesen Abschnitten deaktivieren.",
        text29: "Folder Intelligence (ehemals Webcover)",
        text30: "Google Ads",
        text31: "Google Campaign Manager 360 (ehemaliges DoubleClick)",
        text32: "Sozialen Medien",
        text33: "Ihre Rechte als Dateneigentümer",
        text34: "Sie können jederzeit von Ihrem Recht auf Zugang zu Ihren Daten Gebrauch machen. Darüber hinaus können Sie, wenn die entsprechenden Bedingungen erfüllt sind, von Ihren Rechten gemäß Artikel 11 des Gesetzes und den folgenden Rechten Gebrauch machen:",
        text35: "Recht auf Berichtigung",
        text36: "Recht auf Löschung",
        text37: "Recht auf Einschränkung der Verarbeitung",
        text38: "Beschwerderecht bei der zuständigen Datenschutzaufsichtsbehörde",
        text39: "Recht auf Datenübertragbarkeit (ab 25. Mai 2018)",
        text40: "Widerspruchsrecht",
        text41: "Bei Tätigkeiten im Zusammenhang mit der Verarbeitung Ihrer personenbezogenen Daten auf der Grundlage, dass Minoras einen rechtlichen Vorteil hat, haben Sie aus Gründen, die sich aus Ihrer besonderen Situation ergeben, jederzeit das Recht, der Verarbeitung Ihrer personenbezogenen Daten zu widersprechen Minoras wird die Verarbeitung der Daten einstellen, es sei denn, Minoras kann nachweisen, dass ein wichtiger Grund für die Verarbeitung vorliegt, der schutzwürdig ist und Ihre Interessen, Rechte und Freiheiten überwiegen, oder dieser Vorgang dient der Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen Behauptungen.",
        text42: "Wenn Sie in die Verarbeitung Ihrer personenbezogenen Daten einwilligen, können Sie Ihre Einwilligung mit Wirkung ab sofort widerrufen.",
        text43: "Im Rahmen der gesetzlichen Bestimmungen können Sie Anfragen, Beschwerden und Anregungen aller Art zu Ihren personenbezogenen Daten an die unten angegebene Adresse, mit Dokumenten zum Nachweis Ihrer Identität und Ihrem Antrag, der Ihr Anliegen enthält, oder an die registrierte E-Mail-Adresse unseres Unternehmens (kucukasyaturizm@hs01.kep.tr) senden.",
        text44: "Adresse: Küçük Asya Turizm A.Ş., Esentepe Mah. Koral Han Ap. Nr: 40/A Sisli Istanbul Türkei"
    },
    {
        id: "FR",
        header: "Politique de confidentialité",
        text1: "Küçük Asya Turizm A.Ş, que l'on appellera désormais Minoras, a adopté le principe de protéger la vie privée des visiteurs de son site Internet.",
        text2: "Dans ce contexte, en tant que Minoras, nous respectons les dispositions de la loi n° 6698 sur la protection des données personnelles (« Loi »), les décisions du Comité de protection des données personnelles (« Conseil ») et, le cas échéant, les dispositions de la Commission européenne. Règlement de l'Union sur la protection des données (« RGPD ») afin de traiter les données personnelles conformément à la loi.",
        text3: "Nous souhaitons vous informer sur le type d'informations sur les utilisateurs traitées par Minoras et sur la manière dont elles sont utilisées.",
        text4: "Les modifications éventuelles de cette politique de confidentialité et de cookies seront publiées sur cette page. Dans ce cadre, vous pourrez recevoir des informations à jour sur les informations stockées et comment ces informations sont utilisées.",
        text5: "Cette politique de confidentialité et de cookies ne s'applique pas aux autres sites Web qui ont une extension de lien du site Minoras.",
        text6: "Collecte, utilisation et traitement des données personnelles",
        text7: "Nom de l'institution responsable : Küçük Asya Turizm A.Ş.",
        text8: "Adresse de l'institution responsable : Esentepe Mah. Koral Han Ap. N° : 40/A Sisli - Istanbul",
        text9: "Minoras utilise les données personnelles pertinentes afin de présenter ce site Web aux utilisateurs et de s'assurer que ce site Web fonctionne correctement et que la sécurité nécessaire est fournie. Toute transaction en dehors de ce cadre et effectuée sur les données est effectuée sur la base d'autres obligations légales, autorisations, situations dans lesquelles Minoras a un intérêt légitime ou dans le cadre du consentement exprès donné à Minoras par l'utilisateur concerné.",
        text10: "Minoras stocke et traite les données pertinentes spécifiquement aux fins suivantes :",
        text11: "Minoras collecte et stocke automatiquement certaines données lorsque les utilisateurs visitent le site Web. Ces données comprennent : l'adresse IP ou l'identifiant de l'appareil attribué à l'appareil final concerné, nécessaire pour transmettre le contenu demandé (par exemple, en particulier le contenu, les textes, les images et les fichiers de données fournis pour le téléchargement, etc.), les activités des utilisateurs dans le cadre du site Web , le type de terminal concerné, le type de navigateur utilisé, ainsi que la date et l'heure d'utilisation.",
        text12: "Minoras conserve ces informations afin de détecter et de surveiller d'éventuelles utilisations abusives.",
        text13: "Dans le même temps, Minoras utilise ces informations pour améliorer la fourniture des services, leurs caractéristiques, leurs fonctions et leurs tâches administratives générales.",
        text14: "En outre, Minoras supprime ou anonymise les données utilisées, y compris les adresses IP, sans retard injustifié, lorsque ces données ne sont plus nécessaires aux fins indiquées ci-dessus.",
        text15: "Le traitement et l'utilisation des données pertinentes signifient que (1) le traitement est nécessaire pour que le site Web soit proposé ; ou (2) Dans les cas où Minoras a un intérêt légitime à assurer et à améliorer la fonctionnalité et le bon fonctionnement du site Web, ainsi qu'à s'assurer qu'il est adapté aux besoins des utilisateurs, il est effectué sur la base des dispositions légales pertinentes qui permettent d'effectuer ces opérations.",
        text16: "Aux destinataires des données collectées ; les membres des services internes responsables, les autres sociétés affiliées à Minoras, les prestataires de services externes (par exemple, hébergement et gestion de contenu, agences de marketing, autres prestataires tiers si nécessaire en raison des services qu'ils fournissent), dans le cadre de leurs obligations et pouvoirs respectifs ; par exemple, les autorités compétentes et les actionnaires actuels des sociétés Minoras ou les futurs actionnaires en cas de vente, fusion ou acquisition, si une demande formelle est faite ou nécessaire pour établir, exercer ou défendre les droits de Minoras.",
        text17: "Finalités du traitement des données personnelles",
        text18: "Contact / Formulaire de demande client",
        text19: "Vous avez peut-être partagé vos coordonnées avec nous via le formulaire de contact sur notre site Web ou via les numéros de notre centre d'appels sur l'onglet correspondant.",
        text20: "Nous utiliserons vos données pour répondre à votre demande.",
        text21: "Le traitement et l'utilisation de vos données sont effectués sur la base des dispositions légales et/ou de l'intérêt légitime de Minoras, ce qui permet de le faire si le traitement est nécessaire pour répondre à votre demande actuelle.",
        text22: "À moins que vous n'ayez donné votre consentement pour une période plus longue par le biais d'un consentement exprès, nous ne conserverons les données que pendant la période nécessaire pour atteindre l'objectif ci-dessus ou toute exigence légale de conservation.",
        text23: "Cookies, pixels et technologies similaires",
        text24: "Ce site Web utilise des cookies et des technologies similaires. Les cookies sont de petites unités de données nécessaires à l'utilisation de notre site Web et sont temporairement stockées sur votre disque dur par votre navigateur. Un cookie contient généralement un identifiant unique, qui est un nombre généré aléatoirement et qui est stocké sur votre appareil. Certains de ces identifiants expirent à la fin de votre session de site Web, tandis que d'autres peuvent rester sur votre appareil pendant de plus longues périodes. Les cookies techniquement nécessaires seront utilisés automatiquement. D'autres cookies (ou technologies similaires) ne seront appliqués qu'avec votre consentement préalable.",
        text25: "Il existe différents types de cookies. Les cookies internes sont placés par le site Web visité et ne peuvent être lus que par ce site Web. Les cookies tiers, en revanche, sont placés par d'autres organisations dont nous recevons des services pour divers services que nous utilisons. À titre d'exemple, nous utilisons des fournisseurs de services d'analyse externes qui placent des cookies en notre nom afin d'identifier le contenu apprécié ou non sur notre site Web. En outre, le site Web que vous visitez peut contenir du contenu intégré provenant d'une source telle que YouTube, par exemple, et des cookies peuvent également être placés par cette source.",
        text26: "Un pixel n'est généralement qu'une image transparente de 1 pixel x 1 pixel qui est placée sur un site Web dans le but de personnaliser le contenu ou de rendre l'expérience du navigateur plus efficace et pratique, et collecte certaines données telles que l'adresse IP, le type de navigateur, le temps d'accès ou cookies prédéfinis. Ils sont souvent utilisés en conjonction avec des cookies car les pixels créent et gèrent les cookies.",
        text27: "Le site Web collectera le système d'exploitation de l'appareil, le navigateur, les polices installées, l'adresse IP, les plug-ins et des informations plus détaillées si vous donnez votre consentement. De cette manière, l'appareil peut être ré-identifié à des fins de marketing pour l'utilisateur.",
        text28: "Vous pouvez consulter les sections Cookies et Paramètres des cookies sur notre site Web pour obtenir des informations plus détaillées sur les cookies et autres technologies utilisées sur notre site Web et leurs objectifs. Dans le même temps, vous pouvez retirer vos consentements existants avec effet à partir de maintenant en désactivant les paramètres de cookies correspondants à partir de ces sections.",
        text29: "Folder Intelligence (ancienne couverture Web)",
        text30: "Google Ads",
        text31: "Google Campaign Manager 360 (anciennement DoubleClick)",
        text32: "Média social",
        text33: "Vos droits en tant que propriétaire des données",
        text34: "Vous pouvez toujours exercer votre droit d'accès à vos données. De plus, si les conditions pertinentes sont remplies, vous pouvez exercer vos droits en vertu de l'article 11 de la Loi et les droits suivants :",
        text35: "Droit de corriger",
        text36: "Droit de suppression",
        text37: "Droit de restreindre le traitement",
        text38: "Droit de déposer une plainte auprès de l'autorité de contrôle compétente en matière de protection des données",
        text39: "Droit à la portabilité des données (au 25 mai 2018)",
        text40: "Droit d'opposition",
        text41: "En ce qui concerne les activités liées au traitement de vos données personnelles sur la base que Minoras a un avantage juridique, vous avez toujours le droit de vous opposer au traitement de vos données personnelles pour des raisons découlant de la situation particulière dans laquelle vous vous trouvez. Minoras arrêtera le traitement des données à moins que Minoras ne puisse prouver qu'il existe une justification importante pour le traitement qui doit être protégé et qui l'emporte sur vos intérêts, droits et libertés, ou à moins que ce processus ne serve à des fins telles que la création, l'exercice ou la défense de droits légaux. réclamations.",
        text42: "Si vous donnez votre consentement pour le traitement de vos données personnelles, vous pouvez retirer votre consentement avec effet à partir de maintenant.",
        text43: "Dans le cadre de la loi, vous pouvez envoyer toutes sortes de demandes, réclamations et suggestions concernant vos données personnelles à l'adresse ci-dessous, avec des documents prouvant votre identité et votre pétition contenant votre demande, ou à l'adresse e-mail enregistrée de notre société kucukasyaturizm@hs01.kep.tr en utilisant une signature électronique sécurisée.",
        text44: "Adresse : Küçük Asya Turizm A.Ş., Esentepe Mah. Koral Han Ap. No: 40/A Sisli Istanbul Turquie"
    },
    {
        id: "ES",
        header: "Política de privacidad",
        text1: "Küçük Asya Turizm A.Ş, que a partir de ahora se denominará Minoras, ha adoptado el principio de proteger la privacidad de los visitantes de su sitio web.",
        text2: "En este contexto, como Minoras, cumplimos con las disposiciones de la Ley de Protección de Datos Personales N ° 6698 (“Ley”), las decisiones de la Junta de Protección de Datos Personales (“Junta”) y, en su caso, las disposiciones de la Reglamento de protección de datos de la Unión (GDPR) para procesar datos personales de acuerdo con la ley.",
        text3: "Nos gustaría informarle sobre qué tipo de información sobre los usuarios procesa Minoras y cómo se utiliza.",
        text4: "Los posibles cambios a esta Política de privacidad y cookies se publicarán en esta página. En este marco, podrá recibir información actualizada sobre qué información se almacena y cómo se utiliza esta información.",
        text5: "Esta Política de privacidad y cookies no se aplica a otros sitios web que tienen una extensión de enlace desde el sitio de Minoras.",
        text6: "Recopilación, uso y procesamiento de datos personales",
        text7: "Nombre de la institución responsable: Küçük Asya Turizm A.Ş.",
        text8: "Dirección de la institución responsable: Esentepe Mah. Koral Han Ap. No: 40 / A Sisli - Estambul",
        text9: "Minoras utiliza los datos personales relevantes para presentar este sitio web a los usuarios y garantizar que este sitio web funcione correctamente y que se proporcione la seguridad necesaria. Toda transacción fuera de este ámbito y realizada sobre los datos se realiza sobre la base de otras obligaciones legales, permisos, situaciones en las que Minoras tiene un interés legítimo o en el marco del consentimiento expreso otorgado a Minoras por el usuario correspondiente.",
        text10: "Minoras almacena y procesa los datos relevantes específicamente para los siguientes propósitos:",
        text11: "Minoras recopila y almacena automáticamente ciertos datos cuando los usuarios visitan el sitio web. Estos datos incluyen: dirección IP o ID de dispositivo asignado al dispositivo final relevante, requerido para transmitir el contenido solicitado (por ejemplo, especialmente contenido, textos, imágenes y archivos de datos proporcionados para su descarga, etc.), las actividades de los usuarios en el contexto del sitio web , el tipo de dispositivo final relevante, el tipo de navegador utilizado y la fecha y hora de uso.",
        text12: "Minoras guarda esta información para detectar y monitorear cualquier uso indebido.",
        text13: "Al mismo tiempo, Minoras utiliza esta información para mejorar la prestación de servicios, sus características, funciones y tareas administrativas generales.",
        text14: "Además, Minoras elimina o anonimiza los datos en uso, incluidas las direcciones IP, sin demoras indebidas, cuando dichos datos ya no son necesarios para los fines indicados anteriormente.",
        text15: "El procesamiento y uso de los datos relevantes significa que (1) el procesamiento es necesario para que se ofrezca el sitio web; o (2) En los casos en que Minoras tenga un interés legítimo en garantizar y mejorar la funcionalidad y el buen funcionamiento del sitio web, así como en asegurarse de que sea adecuado para las necesidades de los usuarios, se lleve a cabo sobre la base de las disposiciones legales pertinentes. que permiten realizar estas transacciones.",
        text16: "A los destinatarios de los datos recopilados; miembros de los departamentos internos responsables, otras empresas afiliadas a Minoras, proveedores de servicios externos (por ejemplo, alojamiento y gestión de contenidos, agencias de marketing, otros proveedores externos si es necesario debido a los servicios que prestan), en el marco de sus respectivas obligaciones y competencias; por ejemplo, autoridades relevantes y accionistas actuales de las empresas de Minoras o futuros accionistas en caso de venta, fusión o adquisición, si se hace o es necesario una solicitud formal para establecer, ejercer o defender los derechos que tiene Minoras.",
        text17: "Finalidades del procesamiento de datos personales",
        text18: "Formulario de solicitud de contacto / cliente",
        text19: "Es posible que haya compartido su información de contacto con nosotros a través del formulario de contacto en nuestro sitio web o mediante los números de nuestro centro de llamadas en la pestaña correspondiente.",
        text20: "Usaremos sus datos para responder a su solicitud.",
        text21: "El procesamiento y uso de sus datos se lleva a cabo en base a las disposiciones legales y / o al interés legítimo de Minoras, lo que le permite hacerlo si el procesamiento es necesario para atender su solicitud actual.",
        text22: "A menos que haya dado su consentimiento por un período de tiempo más largo a través de cualquier consentimiento expreso, solo retendremos los datos durante el período necesario para lograr el objetivo anterior o cualquier requisito legal de retención.",
        text23: "Cookies, píxeles y tecnologías similares",
        text24: "Este sitio web utiliza cookies y tecnologías similares. Las cookies son pequeñas unidades de datos que se requieren para utilizar nuestro sitio web y que su navegador almacena temporalmente en su disco duro. Una cookie generalmente contiene un identificador único, que es un número generado aleatoriamente y se almacena en su dispositivo. Algunos de estos identificadores caducan cuando finaliza la sesión de su sitio web, mientras que otros pueden permanecer en su dispositivo durante períodos de tiempo más prolongados. Las cookies técnicamente necesarias se utilizarán automáticamente. Otras cookies (o tecnologías similares) solo se aplicarán con su consentimiento previo.",
        text25: "Existen diferentes tipos de cookies. Las cookies de origen son colocadas por el sitio web visitado y solo pueden ser leídas por ese sitio web. Las cookies de terceros, por otro lado, son colocadas por otras organizaciones de las que recibimos servicios para varios servicios que utilizamos. Por ejemplo, utilizamos proveedores de servicios de análisis externos que colocan cookies en nuestro nombre para identificar el contenido que le gusta o que no le gusta en nuestro sitio web. Además, el sitio web que está visitando puede contener contenido incrustado de una fuente como YouTube, por ejemplo, y esa fuente también puede colocar cookies.",
        text26: "Por lo general, un píxel es solo una imagen transparente de 1 píxel x 1 píxel que se coloca en un sitio web con el propósito de personalizar el contenido o hacer que la experiencia del navegador sea más efectiva y conveniente, y recopila ciertos datos como la dirección IP, el tipo de navegador, el tiempo de acceso o cookies preestablecidas. A menudo se utilizan junto con las cookies porque los píxeles crean y administran las cookies.",
        text27: "El sitio web recopilará el sistema operativo del dispositivo, el navegador, las fuentes instaladas, la dirección IP, los complementos e información más detallada si usted da su consentimiento. De esta manera, el dispositivo se puede volver a identificar con fines de marketing para el usuario.",
        text28: "Puede consultar las secciones Cookies y Configuración de cookies en nuestro sitio web para obtener información más detallada sobre las cookies y otras tecnologías utilizadas en nuestro sitio web y sus propósitos. Al mismo tiempo, puede retirar sus consentimientos existentes con efecto a partir de ahora deshabilitando la configuración de cookies correspondiente desde estas secciones.",
        text29: "Folder Intelligence (antigua portada web)",
        text30: "Google Ads",
        text31: "Google Campaign Manager 360 (antiguo DoubleClick)",
        text32: "Social Media",
        text33: "Sus derechos como propietario de los datos",
        text34: "Siempre puede ejercer su derecho de acceso a sus datos. Además, si se cumplen las condiciones pertinentes, puede hacer uso de sus derechos en el artículo 11 de la Ley y los siguientes derechos:",
        text35: "Derecho a corregir",
        text36: "Derecho a borrar",
        text37: "Derecho a restringir el procesamiento",
        text38: "Derecho a presentar una reclamación a través de la autoridad supervisora de protección de datos competente",
        text39: "Derecho a la portabilidad de datos (a 25 de mayo de 2018)",
        text40: "Derecho a oponerse",
        text41: "Cuando se trata de las actividades relacionadas con el procesamiento de sus datos personales sobre la base de que Minoras tiene un beneficio legal, siempre tiene derecho a oponerse al procesamiento de sus datos personales por las razones que surjan de la situación especial en la que se encuentra. . Minoras detendrá el procesamiento de datos a menos que Minoras pueda probar que existe una justificación importante para el procesamiento que necesita ser protegido y que prevalece sobre sus intereses, derechos y libertades, o a menos que este proceso sirva para propósitos tales como hacer, ejercer o defender legal reclamación (es.",
        text42: "Si da su consentimiento para el tratamiento de sus datos personales, puede retirar su consentimiento con efecto a partir de ahora.",
        text43: "En el ámbito de la ley, puede enviar todo tipo de solicitudes, quejas y sugerencias sobre sus datos personales a la dirección que figura a continuación, con documentos que acrediten su identidad y su petición que contenga su solicitud, o al correo electrónico registrado de nuestra empresa kucukasyaturizm@hs01.kep.tr mediante el uso de una firma electrónica segura.",
        text44: "Dirección: Küçük Asya Turizm A.Ş., Esentepe Mah. Koral Han Ap. No: 40 / A Sisli Estambul Turquía"
    },
    {
        id: "TR",
        header: "Gizlilik Politikası",
        text1: "Bundan böyle Minoras olarak anılacak Küçük Asya Turzim Anonim Şirketi, web sitesini ziyaret edenlerin gizliliğini korumayı ilke olarak benimsemiştir.",
        text2: "Bu kapsamda Minoras olarak, kişisel verilerin hukuka uygun işlenmesi adına 6698 sayılı Kişisel Verilerin Korunması Kanunu (“Kanun“) hükümlerine, Kişisel Verileri Koruma Kurulu (“Kurul“) kararlarına ve uygun düştüğü ölçüde Avrupa Birliği Veri Koruma Tüzüğü (“GDPR“) hükümlerine uyum sağlamak için gerekli her türlü tedbir ve aksiyonu alıyoruz. ",
        text3: "Sizleri, kullanıcılara ilişkin ne tür bilgilerin Minoras tarafından işlendiğini ve bunların nasıl kullanıldığı konusunda bilgilendirmek istiyoruz. Aynı zamanda sizleri, veri sahibi olarak ilgili haklarınızı nasıl kullanabileceğinize dair bilgilendireceğiz.",
        text4: "İşbu Gizlilik ve Çerez Politikası‘na ilişkin olası değişiklikler bu sayfada yayımlanacaktır. Bu çerçevede, hangi bilgilerin saklandığına ve bu bilgilerin nasıl kullanıldığına dair güncel olarak bilgi almanıza olanak sağlanacaktır.",
        text5: "İşbu Gizlilik ve Çerez Politikası, Minoras sitesinden bağlantı uzantısı verilmiş başka web siteleri için geçerli değildir.",
        text6: "Kişisel Verilerin Toplanması, Kullanımı ve İşlenmesi",
        text7: "Sorumlu kuruluşun adı: Küçük Asya Turizm Anonim Şirketi",
        text8: "Sorumlu kuruluşun adresi: Esentepe Mah. Koral Han Ap. No: 40/A Şişli - İstanbul",
        text9: "Minoras, ilgili kişisel verileri bu web sitesini kullanıcılara sunmak ve bu sitenin uygun şekilde çalıştığının ve gerekli ölçüde güvenliğin sağlandığının temin edilmesi doğrultusunda kullanır. Bu kapsam dışında kalan ve veriler üzerinde gerçekleştirilen her işlem, diğer yasal yükümlülükler, zinler, Minoras’in meşru menfaatinin bulunduğu haller zemininde ya da ilgili kullanıcının Minoras’e verdiği açık rıza çerçevesinde gerçekleşmektedir.",
        text10: "Minoras, ilgili verileri özel olarak şu amaçlar doğrultusunda saklar ve işler:",
        text11: "Kullanıcılar web sitesini ziyaret ettiğinde Minoras belirli verileri otomatik olarak toplar ve saklar. Bu verilere; Talep edilen içeriği (örn. özellikle içeriği, metinleri, görselleri ve indirme için sağlanan veri dosyalarını vb.) iletebilmek için gerek duyulan, ilgili uç cihaza tahsis edilmiş IP adresi ya da cihaz kimliği, kullanıcıların web sitesi bağlamındaki aktiviteleri, ilgili uç cihaz türü, kullanılan tarayıcı türü ve kullanım tarihi ve saati dahildir.",
        text12: "Minoras, bu bilgileri amaç dışında kullanımı tespit ve takip edebilmek üzere saklamaktadır.",
        text13: "Aynı zamanda Minoras, hizmetlerin sunumunu, özelliklerini, işlevlerini ve genel yönetim görevlerini geliştirmek için de bu bilgileri kullanır.",
        text14: "Bunların yanında Minoras, yukarıda verilen amaçlar doğrultusunda söz konusu verilere artık gerek duyulmadığında, IP adresleri de dahil olmak üzere kullanımdaki verileri usule aykırı gecikme olmaksızın siler ya da anonim hale getirir.",
        text15: "İlgili verilerin işlenmesi ve kullanımı, (1) işlemenin web sitesinin sunulabilmesi için gerekli olduğu; ya da (2) Minoras'ın, web sitesinin işlevselliğini ve sorunsuz biçimde çalışmasını, aynı zamanda da kullanıcıların gereksinimlerine uygun olmasını temin eden ve geliştiren meşru bir menfaate sahip olduğu durumlarda, bu işlemlerin yapılabilmesine imkan sağlayan ilgili yasa hükümlerinin oluşturduğu zemin doğrultusunda gerçekleştirilmektedir.",
        text16: "Toplanan verilerin alıcılarına; sorumlu bulunan kurum içi departmanların üyeleri, Minoras'a bağlı diğer şirketler, dış hizmet sağlayıcıları (örn. hosting ve içerik yönetimi, pazarlama ajansları, sağladıkları hizmetler dolayısıyla gerekli olması halinde diğer üçüncü taraf sağlayıcılar), ilgili yükümlülükleri ve yetkileri çerçevesinde; örneğin, resmi bir talepte bulunulması ya da Minoras'ın sahip olduğu hakları tespit etmek, uygulamak ya da savunmak üzere gerekli olması halinde ilgili yetkililer ve Minoras şirketlerinin mevcut hissedarları ya da satış, birleşme ya da satın alma durumunda gelecekteki hissedarları dahil olabilir.",
        text17: "Kişisel Verilerin İşlenme Amaçları",
        text18: "İletişim / Müşteri Talep Formu",
        text19: "İletişim bilgilerinizi web sitemizde yer alan “Bize ulaşın” isimli iletişim formu üzerinden veya ilgili sekmede yer alan çağrı merkezi numaralarımız aracılığıyla bizimle paylaşmış olabilirsiniz.",
        text20: "Size ait verileri talebinize cevap vermek amacıyla kullanacağız.",
        text21: "Verilerinizin işlenmesi ve kullanımı, mevcut talebinizi karşılamak için işlemenin gerekli olması halinde bunun yapılabilmesine imkan sağlayan yasa hükümlerinin ve/veya Minoras'ın meşru menfaatinin oluşturduğu zemin doğrultusunda gerçekleştirilmektedir.",
        text22: "Herhangi bir açık rıza beyanı vasıtasıyla daha uzun bir süre için onay vermediğiniz durumda, verileri yalnızca yukarıda verilen hedefi gerçekleştirmek için gerekli süre ya da herhangi bir yasal saklama zorunluluğunun gerektirdiği dönem boyunca saklayacağız.",
        text23: "Çerezler, Pikseller ve Benzer Teknolojiler",
        text24: "Bu web sitesi, çerezleri ve benzeri teknolojileri kullanmaktadır. Çerezler, web sitemizi kullanmak için gerekli olan ve tarayıcınız tarafından sabit diskinize geçici olarak depolanan küçük veri birimleridir. Bir çerez genellikle, rastgele şekilde oluşturulmuş bir sayıdan oluşan ve cihazınıza depolanan benzersiz bir tanımlayıcı içerir. Bu tanımlayıcıların bazıları web sitesi oturumunuz sona erdiğinde geçerliliğini yitirirken, bazıları ise daha uzun süreler boyunca cihazınızda kalabilir. Teknik açıdan gerekli çerezler otomatik olarak kullanılacaktır. Diğer çerezler (ya da benzeri teknolojiler) yalnızca ön onayınız üzerine uygulanacaktır.",
        text25: "Farklı türde çerezler bulunmaktadır. Birinci taraf çerezleri, ziyaret edilen web sitesi tarafından yerleştirilir ve yalnızca o web sitesi tarafından okunabilir. Üçüncü taraf çerezleri ise, kullandığımız çeşitli servisler için hizmet aldığımız diğer kuruluşlar tarafından yerleştirilmektedir. Buna örnek olarak, web sitemizde beğenilen ya da beğenilmeyen içerikleri tespit edebilmek amacıyla bizim adımıza çerezler yerleştiren harici analitik hizmeti sağlayıcıları kullanıyoruz. Buna ek olarak, ziyaret ettiğiniz web sitesi, örneğin, YouTube gibi bir kaynaktan alınmış gömülü bir içerik barındırabilir ve çerezler bu kaynak tarafından da yerleştirilebilir.",
        text26: "Bir piksel genellikle, içerik kişiselleştirme ya da tarayıcı deneyimini daha etkili ve kolay hâle getirme amacı doğrultusunda bir web sitesine yerleştirilen ve IP adresi, tarayıcı türü, erişim zamanı ya da önceden ayarlanan çerezler gibi belirli verileri toplayan yalnızca 1 piksel x 1 piksel ölçülerindeki şeffaf bir görseldir. Piksellerin çerezleri oluşturup yönetmesi nedeniyle sıklıkla çerezlerle birlikte kullanılırlar.",
        text27: "Web sitesi, onay vermeniz halinde cihazın işletim sistemi, tarayıcısı, yüklü yazı tipleri, IP adresi, eklentileri ve daha detaylı bilgileri toplayacaktır. Bu sayede cihaz, kullanıcıya ilişkin pazarlama amaçları doğrultusunda yeniden tanınabilir.",
        text28: "Çerezler ve web sitemizde kullanılan diğer teknolojiler ve bunların kullanım amaçları hakkında daha detaylı bilgi almak için web sitemizde yer alan “Çerezler” ve “Çerez ayarları” bölümlerine göz atabilirsiniz. Aynı zamanda bu bölümler içerisinden, ilgili çerez ayarlarını devre dışı bırakarak mevcut onaylarınızı bundan böyle geçerli olmak üzere geri çekebilirsiniz.",
        text29: "Mapp Intelligence (önceki adıyla Webtrekk)",
        text30: "Google Ads",
        text31: "Google Campaign Manager 360 (önceki adıyla DoubleClick)",
        text32: "Sosyal Medya",
        text33: "Veri Sahibi Olarak Haklarınız",
        text34: "Her zaman için verilerinize erişme hakkınızı kullanabilirsiniz. Buna ek olarak, ilgili şartlar yerine getirildiği takdirde Kanun’un 11. maddesinde yer alan haklarınızı ve şu haklarınızı da kullanabilirsiniz:",
        text35: "Düzeltme hakkı",
        text36: "Silme hakkı",
        text37: "İşlemeyi kısıtlama hakkı",
        text38: "Yetkili veri koruma denetim kurumu üzerinden bir şikayet oluşturma hakkı",
        text39: "Veri taşınabilirliği hakkı (25 Mayıs 2018 itibariyle)",
        text40: "İtiraz hakkı",
        text41: "Kişisel verilerinizin, Minoras'ın yasal bir faydaya sahip olduğu zeminde işlenmesine ilişkin etkinlikler söz konusu olduğunda, içinde bulunduğunuz özel durum sonucunda oluşan gerekçeler dolayısıyla, her zaman için kişisel verilerinizin işlenmesine itiraz hakkınız bulunmaktadır. Minoras, verilerin işlenmesine ilişkin olarak korunması gereken ve çıkarlarınızın, haklarınızın ve özgürlüklerinizin üzerinde yer alan önemli bir gerekçe olduğunu kanıtlayamadığı ya da bu işlem yasal hak taleplerde bulunmak, bu talepleri kullanmak ya da savunmak gibi amaçlara hizmet etmediği sürece Minoras verilerin işlenmesini durduracaktır.",
        text42: "Kişisel verilerinizin işlenmesine onay vermeniz halinde, bundan böyle geçerli olmak üzere bu onayınızı geri çekebilirsiniz.",
        text43: "Kanun kapsamında, kişisel verilerinizle ilgili her türlü talep, şikâyet ve önerilerinizi, kimliğinizi tevsik edici belgeler ve talebinizi içeren dilekçeniz ile aşağıdaki adresimize ulaştırabilir veya güvenli elektronik imza kullanmak suretiyle şirketimizin kucukasyaturizm@hs01.kep.tr kayıtlı elektronik posta adresine gönderebilirsiniz.",
        text44: "Adres: Küçük Asya Turizm A.Ş., Esentepe Mah. Koral Han Ap. No: 40/A Şişli İstanbul Türkiye"
    }
];

class GizlilikPolitikasi extends Component {
    componentDidMount() {
        const text = `Privacy: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (  
            <div>
                <MetaTags>
                    <title>Minoras | {lItem.header}</title>
                    <meta name="description" content="Turkey Cappadocia Gobeklitepe Pamukkale Hierapolis Aphrodisias Halfeti Gaziantep Sanliurfa Zeugma Ephesus Privacy Policy" />
                </MetaTags>
                <HeaderSub header={lItem.header}/>
                <div className="Page">    
                    <div className="PageText">
                    {lItem.text1}
                    </div>
                    <div className="PageText">
                    {lItem.text2}
                    </div>
                    <div className="PageText">
                    {lItem.text3}
                    </div>
                    <div className="PageText">
                    {lItem.text4}
                    </div>
                    <div className="PageText">
                    {lItem.text5}
                    </div>
                    <div className="PageSection">
                    {lItem.text6}
                    </div>
                    <div className="PageText">
                    {lItem.text7}
                    </div>
                    <div className="PageText">
                    {lItem.text8}
                    </div>
                    <div className="PageText">
                    {lItem.text9}
                    </div>
                    <div className="PageText">
                    {lItem.text10}
                    </div>
                    <div className="PageText">
                    {lItem.text11}
                    </div>
                    <div className="PageText">
                    {lItem.text12}
                    </div>
                    <div className="PageText">
                    {lItem.text13}
                    </div>
                    <div className="PageText">
                    {lItem.text14}
                    </div>
                    <div className="PageText">
                    {lItem.text15}
                    </div>
                    <div className="PageText">
                    {lItem.text16}
                    </div>
                    <div className="PageSection">
                    {lItem.text17}
                    </div>
                    <div className="PageText">
                    {lItem.text18}
                    </div>
                    <div className="PageText">
                    {lItem.text19}
                    </div>
                    <div className="PageText">
                    {lItem.text20}
                    </div>
                    <div className="PageText">
                    {lItem.text21}
                    </div>
                    <div className="PageText">
                    {lItem.text22}
                    </div>
                    <div className="PageSection">
                    {lItem.text23}
                    </div>
                    <div className="PageText">
                    {lItem.text24}
                    </div>
                    <div className="PageText">
                    {lItem.text25}
                    </div>
                    <div className="PageText">
                    {lItem.text26}
                    </div>
                    <div className="PageText">
                    {lItem.text27}
                    </div>
                    <div className="PageText">
                    {lItem.text28}
                    </div>
                    <ul className="PageBullet">
                    <li>{lItem.text29}</li>
                    <li>{lItem.text30}</li>
                    <li>{lItem.text31}</li>
                    <li>{lItem.text32}</li>
                    </ul>
                    <div className="PageSection">
                    {lItem.text33}
                    </div>
                    <div className="PageText">
                    {lItem.text34}
                    </div>
                    <ul className="PageBullet">
                    <li>{lItem.text35}</li>
                    <li>{lItem.text36}</li>
                    <li>{lItem.text37}</li>
                    <li>{lItem.text38}</li>
                    <li>{lItem.text39}</li>
                    <li>{lItem.text40}</li>
                    </ul>
                    <div className="PageText">
                    {lItem.text41}
                    </div>
                    <div className="PageText">
                    {lItem.text42}
                    </div>
                    <div className="PageText">
                    {lItem.text43}
                    </div>
                    <div className="PageText">
                    {lItem.text44}
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(GizlilikPolitikasi);
