import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger, sendContactForm } from './actions/account';
import queryString from 'query-string';
import MetaTags from 'react-meta-tags';

import HeaderSub from './HeaderSub';

const lList = [
    {
        id: "EN",
        header: "Contact Form Received",
        text1: "The contact form you filled in reached us with the information you see below. Minoras customer service will contact you as soon as possible. Thank you for writing to us.",
        text2: "Name",
        text3: "Surname",
        text4: "Phone",
        text5: "E-Mail",
        text6: "Subject",
        text7: "Message"
    },
    {
        id: "DE",
        header: "Kontaktformular Erhalten",
        text1: "Das von Ihnen ausgefüllte Kontaktformular hat uns mit den unten aufgeführten Informationen erreicht. Der Minoras-Kundendienst wird sich so schnell wie möglich mit Ihnen in Verbindung setzen. Vielen Dank, dass Sie uns geschrieben haben.",
        text2: "Name",
        text3: "Familienname",
        text4: "Telefon",
        text5: "E-Mail",
        text6: "Betreff",
        text7: "Nachricht"
    },
    {
        id: "FR",
        header: "Formulaire de Contact Reçu",
        text1: "Le formulaire de contact que vous avez rempli nous est parvenu avec les informations que vous voyez ci-dessous. Le service client de Minoras vous contactera dans les plus brefs délais. Merci de nous avoir écrit.",
        text2: "Nom",
        text3: "Nom de famille",
        text4: "Téléphoner",
        text5: "E-Mail",
        text6: "référence",
        text7: "Message"
    },
    {
        id: "ES",
        header: "Formulario de Contacto Recibido",
        text1: "El formulario de contacto que completó nos llegó con la información que ve a continuación. El servicio de atención al cliente de Minoras se pondrá en contacto contigo lo antes posible. Gracias por escribirnos.",
        text2: "Nombre",
        text3: "Apellido",
        text4: "Teléfono",
        text5: "Correo electrónico",
        text6: "Referencia",
        text7: "Mensaje"
    },
    {
        id: "TR",
        header: "İletişim Formu Alındı",
        text1: "Doldurduğunuz iletişim formu, aşağıda gördüğünüz bilgilerle elimize ulaştı. En kısa zamanda Minoras müşteri hizmetleri sizinle iletişime geçecek. Bize yazdığınız için teşekkür ederiz.",
        text2: "Ad",
        text3: "Soyad",
        text4: "Telefon",
        text5: "E-Posta",
        text6: "Konu",
        text7: "Mesaj"
    },
];

class ContactFormFiled extends Component {
    state = {
        konu: '',
        ad: '',
        soyad: '',
        tel: '',
        email: '',
        mesaj: ''
    }

    componentDidMount() {
        const parsed = queryString.parse(window.location.search);
        if (parsed && parsed.ileten === "Z") {
            this.setState({
                konu: parsed.konu,
                ad: parsed.ad,
                soyad: parsed.soyad,
                tel: parsed.tel,
                email: parsed.email,
                mesaj: parsed.mesaj
            });
            this.props.invokeLogger({ id: "w", text: `ContactForm: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, Name:${parsed.ad}, Surname:${parsed.soyad}, Phone:${parsed.tel}, Email:${parsed.email}, Subject:${parsed.konu}, Message:${parsed.mesaj}, Dil:${this.props.language}` });
            this.props.sendContactForm({id: 0, user: localStorage.getItem("serialNumber"), konu: parsed.konu, ad: parsed.ad, soyad: parsed.soyad, tel: parsed.tel, email: parsed.email, mesaj: parsed.mesaj, dil: this.props.language });
            localStorage.setItem("userName", parsed.ad);
            localStorage.setItem("userSurname", parsed.soyad);
            localStorage.setItem("userPhone", parsed.tel);
            localStorage.setItem("userEmail", parsed.email);
            localStorage.setItem("userLanguage", this.props.language);
            this.props.changeContactFormSent(true);
        } else {
            this.props.invokeLogger({ id: "w", text: "ContactForm: Unknown cleint, no data" });
        }
        window.scrollTo(0, 0);
    }

    render() {   
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div>
                <MetaTags>
                    <title>Minoras | {lItem.header}</title>
                    <meta name="description" content="Turkey Cappadocia Gobeklitepe Pamukkale Hierapolis Aphrodisias Halfeti Gaziantep Sanliurfa Zeugma Ephesus Contact Form" />
                </MetaTags>
                <HeaderSub header={lItem.header} />
                <div className="Page">
                    <div className="PageText">
                        {lItem.text1}
                    </div>
                    <div className="PageTable">
                        <div className="PageTableRow">
                            <div className="PageTableCell">
                                {lItem.text2}
                            </div>
                            <div className="PageTableCell">
                                {this.state.ad}
                            </div>
                        </div>
                        <div className="PageTableRow">
                            <div className="PageTableCell">
                                {lItem.text3}
                            </div>
                            <div className="PageTableCell">
                                {this.state.soyad}
                            </div>
                        </div>
                        <div className="PageTableRow">
                            <div className="PageTableCell">
                                {lItem.text4}
                            </div>
                            <div className="PageTableCell">
                                {this.state.tel}
                            </div>
                        </div>
                        <div className="PageTableRow">
                            <div className="PageTableCell">
                                {lItem.text5}
                            </div>
                            <div className="PageTableCell">
                                {this.state.email}
                            </div>
                        </div>
                        <div className="PageTableRow">
                            <div className="PageTableCell">
                                {lItem.text6}
                            </div>
                            <div className="PageTableCell">
                                {this.state.konu}
                            </div>
                        </div>
                        <div className="PageTableRow">
                            <div className="PageTableCell">
                                {lItem.text7}
                            </div>
                            <div className="PageTableCell">
                                {this.state.mesaj}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )        
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger, sendContactForm }
)(ContactFormFiled);