import { ACCOUNT } from '../actions/types';
import fetchStates from './fetchStates';

const DEFAULT_ACCOUNT = {
    loggedIn: false,
    visitor: {
        serialNumber: "",
        dateOfFirstVisit: "",
        accountId: "",
        userName: "",
        userSurname: "",
        userPhone: "",
        userEmail: "",
        userLanguage: ""
    }
};

const account = (state = DEFAULT_ACCOUNT, action) => {
    switch(action.type) {
        case ACCOUNT.INVOKELOGGER:
            return { ...state, status: fetchStates.fetching };
        case ACCOUNT.INVOKELOGGER_ERROR:
            return {
                ...state,
                status: fetchStates.error,
                message: action.message
            };
        case ACCOUNT.INVOKELOGGER_SUCCESS:
            return {
                ...state,
                status: fetchStates.success,
                message: action.message
            };
        case ACCOUNT.WINDOWCHECK:
            return { ...state, status: fetchStates.fetching };
        case ACCOUNT.WINDOWCHECK_ERROR:
            return {
                ...state,
                status: fetchStates.error,
                message: action.message
            };
        case ACCOUNT.WINDOWCHECK_SUCCESS:
            let sN = localStorage.getItem("serialNumber");
            if (sN === undefined || sN === null ) {
                localStorage.setItem("serialNumber", action.visitor.serialNumber);
                localStorage.setItem("dateOfFirstVisit", action.visitor.dateOfFirstVisit);
                localStorage.setItem("accountId", action.visitor.accountId);
            }
            return {
                ...state,
                status: fetchStates.success,
                visitor: action.visitor
            };
        default:
            return state;
    }
};

export default account;