import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from './actions/account';
import HeaderSub from './HeaderSub';
import MetaTags from 'react-meta-tags';

const lList = [
    {
        id: "EN",
        header: "Contact",
        text1: "E-Mail",
        text2: "Phone",
        text3: "Address"
    },
    {
        id: "DE",
        header: "Kontakt",
        text1: "Email",
        text2: "Telefon",
        text3: "Addresse"
    },
    {
        id: "FR",
        header: "Contact",
        text1: "E-Mail",
        text2: "Téléphoner",
        text3: "Adresse"
    },
    {
        id: "ES",
        header: "Contacto",
        text1: "Correo electrónico",
        text2: "Teléfono",
        text3: "Habla a"
    },
    {
        id: "TR",
        header: "İletişim",
        text1: "E-Posta",
        text2: "Telefon",
        text3: "Adres"
    }
];

class Iletisim extends Component {
    componentDidMount() {
        const text = `Contact: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (  
            <div>
                <MetaTags>
                    <title>Minoras | {lItem.header}</title>
                    <meta name="description" content="Turkey Cappadocia Gobeklitepe Pamukkale Hierapolis Aphrodisias Halfeti Gaziantep Sanliurfa Zeugma Ephesus Contact" />
                </MetaTags>
                <HeaderSub language={this.props.language} header={lItem.header}/>
                <div className="Page">
                    <div className="PageSection">
                        {lItem.text1}
                    </div>
                    <div className="PageText">
                    <a className="FooterCenterLink" href="mailto:info@minoras.com">info@minoras.com</a>
                    </div>
                    <div className="PageSection">
                        Facebook
                    </div>
                    <div className="PageText">
                        https://www.facebook.com/minorastravel
                    </div>
                    <div className="PageSection">
                        Instagram
                    </div>
                    <div className="PageText">
                        https://instagram/minorastravel
                    </div>
                    <div className="PageSection">
                        {lItem.text2}
                    </div>
                    <div className="PageText">
                        +90 212 235 8888
                    </div>
                    <div className="PageSection">
                        {lItem.text3}
                    </div>
                    <div className="PageText">
                        <div>Merkez Mah.</div>
                        <div>Abide-i Hürriyet Cad. No: 211 İç Kapı No: 67</div>   
                        <div>Şişli - İstanbul</div>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Iletisim);