import React, { Component } from 'react';
import { connect } from 'react-redux';
import { invokeLogger } from './actions/account';
import HeaderSub from './HeaderSub';
import MetaTags from 'react-meta-tags';

const lList = [
    {
        id: "EN",
        header: "About",
        text1: "Tailormade Destination Experience",
        text2: "We provide professional destination management services to our customers to create a unique travel experience",
        text3: "Turnkey travel solutions",
        text4: "We are here to fulfill all your special requirements during your stay",
        text5: "Unforgettable memories",
        text6: "You will be returning home from a dream traveling experience with memories to last forever",
        text7: "You will be returning home from a dream traveling experience with memories to last forever"
    },
    {
        id: "DE",
        header: "About",
        text1: "Tailormade Destination Experience",
        text2: "We provide professional destination management services to our customers to create a unique travel experience",
        text3: "Turnkey travel solutions",
        text4: "We are here to fulfill all your special requirements during your stay",
        text5: "Unforgettable memories",
        text6: "You will be returning home from a dream traveling experience with memories to last forever",
        text7: "You will be returning home from a dream traveling experience with memories to last forever"
    },
    {
        id: "FR",
        header: "About",
        text1: "Tailormade Destination Experience",
        text2: "We provide professional destination management services to our customers to create a unique travel experience",
        text3: "Turnkey travel solutions",
        text4: "We are here to fulfill all your special requirements during your stay",
        text5: "Unforgettable memories",
        text6: "You will be returning home from a dream traveling experience with memories to last forever",
        text7: "You will be returning home from a dream traveling experience with memories to last forever"
    },
    {
        id: "ES",
        header: "About",
        text1: "Tailormade Destination Experience",
        text2: "We provide professional destination management services to our customers to create a unique travel experience",
        text3: "Turnkey travel solutions",
        text4: "We are here to fulfill all your special requirements during your stay",
        text5: "Unforgettable memories",
        text6: "You will be returning home from a dream traveling experience with memories to last forever",
        text7: "You will be returning home from a dream traveling experience with memories to last forever"
    },
    {
        id: "TR",
        header: "Hakkında",
        text1: "Kişiye özel destinasyon deneyimi",
        text2: "Eşsiz bir seyahat deneyimi yaratmak için müşterilerimize profesyonel destinasyon yönetimi hizmetleri sunuyoruz",
        text3: "Anahtar teslim seyahat çözümleri",
        text4: "Konaklamanız süresince tüm özel gereksinimlerinizi yerine getirmek için buradayız",
        text5: "Unutulmaz anılar",
        text6: "Sonsuza dek sürecek anılarla rüya gibi bir seyahat deneyiminden eve döneceksiniz",
        text7: "Sonsuza dek sürecek anılarla rüya gibi bir seyahat deneyiminden eve döneceksiniz"
    }
];

class Hakkimizda extends Component {
    componentDidMount() {
        const text = `About: User_${this.props.account.visitor.serialNumber}, ${this.props.account.visitor.dateOfFirstVisit}, ${this.props.account.visitor.accountId}, ${this.props.account.visitor.userName}, ${this.props.account.visitor.userSurname}, ${this.props.account.visitor.userPhone}, ${this.props.account.visitor.userEmail}, ${this.props.account.visitor.userLanguage}, ${window.navigator.language}, ${window.navigator.vendor}, ${window.navigator.userAgent}, Cookie `+ (window.navigator.cookieEnabled ? "enabled" : "disabled");
        this.props.invokeLogger({ id: "w", text });
        window.scrollTo(0, 0);
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (  
            <div>
                <MetaTags>
                    <title>Minoras | {lItem.header}</title>
                    <meta name="description" content="Turkey Cappadocia Gobeklitepe Pamukkale Hierapolis Aphrodisias Halfeti Gaziantep Sanliurfa Zeugma Ephesus About" />
                </MetaTags>
                <HeaderSub header={lItem.header}/>
                <div className="Page">  
                    <div className="PageText">
                         
                    </div>
                    <div className="PageSection">
                        
                    </div>
                    <div className="PageText">
                        
                    </div>
                    <div className="PageSection">
                        
                    </div>
                    <div className="PageText">
                        
                    </div>
                    <div className="PageBold">
                        
                    </div>
                    <div className="PageText">
                        
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(
    ({ account }) => ({ account }),
    { invokeLogger }
)(Hakkimizda);
