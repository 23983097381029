import React, { Component } from 'react';

class HeaderSub extends Component {
    render () {
        return (
            <div>
                <div className="HeaderSub">
                    <div className="HeaderSubText">
                        {this.props.header}
                    </div>
                </div>
            </div>
        )
    }
}

export default HeaderSub;