import React, { Component } from 'react';
import Carousel from 'react-bootstrap/Carousel';

import image1 from './assets/h_zeugma1.jpg';
import image2 from './assets/h_afrodisias1.jpg';
import image3 from './assets/h_hierapolis1.jpg';

const lList = [
    {
        id: "EN",
        text1: "Tailor-made Destination Experience",
        text2: "We provide professional destination management services to create a unique travel experience for you",
        text3: "Elavate your Business through M.I.C.E.",
        text4: "We are here to fulfill all your special requirements during your tailor-made M.I.C.E. Experience",
        text5: "Unforgettable Memories",
        text6: "You will be returning home from a dream traveling experience with memories to last forever"
    },
    {
        id: "DE",
        text1: "Maßgeschneidertes Reiseziel-Erlebnis",
        text2: "Wir bieten professionelle Destinationsmanagement-Dienstleistungen an, um für Sie ein einzigartiges Reiseerlebnis zu schaffen",
        text3: "Steigern Sie Ihr Geschäft durch M.I.C.E.",
        text4: "Wir sind hier, um alle Ihre speziellen Anforderungen während Ihres maßgeschneiderten M.I.C.E.-Erlebnisses zu erfüllen",
        text5: "Unvergessliche Erinnerungen",
        text6: "Sie werden von einem traumhaften Reiseerlebnis mit Erinnerungen für die Ewigkeit nach Hause zurückkehren"
    },
    {
        id: "FR",
        text1: "Expérience de destination sur mesure",
        text2: "Nous fournissons des services professionnels de gestion de destination pour créer une expérience de voyage unique pour vous",
        text3: "Élevez votre entreprise grâce au M.I.C.E.",
        text4: "Nous sommes là pour répondre à toutes vos exigences particulières lors de votre expérience M.I.C.E. sur mesure",
        text5: "Souvenirs inoubliables",
        text6: "Vous rentrerez chez vous d'une expérience de voyage de rêve avec des souvenirs qui dureront pour toujours"
    },
    {
        id: "ES",
        text1: "Experiencia de destino a medida",
        text2: "Brindamos servicios profesionales de gestión de destinos para crear una experiencia de viaje única para usted",
        text3: "Eleve su negocio a través de M.I.C.E.",
        text4: "Estamos aquí para cumplir con todos sus requisitos especiales durante su Experiencia M.I.C.E. a medida",
        text5: "Memorias inolvidables",
        text6: "Volverá a casa de una experiencia de viaje de ensueño con recuerdos que durarán para siempre"
    },
    {
        id: "TR",
        text1: "Kişiye özel destinasyon deneyimi",
        text2: "Size benzersiz bir seyahat deneyimi yaratmak için profesyonel destinasyon yönetimi hizmetleri sunuyoruz",
        text3: "Kurumunuzu M.I.C.E. ile Zirveye Taşıyın",
        text4: "Size özel M.I.C.E. deneyiminiz sırasında tüm özel gereksinimlerinizi karşılamak için buradayız",
        text5: "Unutulmaz anılar",
        text6: "Sonsuza dek sürecek anılarla rüya gibi bir seyahat deneyiminden eve döneceksiniz"
    }
];

class Header extends Component {

    handleDragStart = (e) => {
        e.preventDefault();
    }

    render() {
        let lItem = lList.find((item) => { return item.id === this.props.language });

        return (
            <div className="Header">
                <Carousel>
                    <Carousel.Item>
                        <img
                            onDragStart={this.handleDragStart}
                            onContextMenu={this.handleDragStart}
                            className="HImgNormal"
                            src={image1} width="100%" alt="Minoras"/>
                        <Carousel.Caption>
                            <div className="HeaderText">
                                <div className="HeaderText1">{lItem.text1}</div>
                                <div className="HeaderText2">{lItem.text2}</div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            onDragStart={this.handleDragStart}
                            onContextMenu={this.handleDragStart}
                            className="HImgNormal"
                            src={image2} width="100%" alt="Minoras"/>
                        <Carousel.Caption>
                            <div className="HeaderText">
                                <div className="HeaderText1">{lItem.text3}</div>
                                <div className="HeaderText2">{lItem.text4}</div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                    <Carousel.Item>
                        <img
                            onDragStart={this.handleDragStart}
                            onContextMenu={this.handleDragStart}
                            className="HImgNormal"
                            src={image3} width="100%" alt="Minoras"/>
                        <Carousel.Caption>
                            <div className="HeaderText">
                                <div className="HeaderText1">{lItem.text5}</div>
                                <div className="HeaderText2">{lItem.text6}</div>
                            </div>
                        </Carousel.Caption>
                    </Carousel.Item>
                </Carousel>
            </div>
        )
    }
}

export default Header;