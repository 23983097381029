import React, { Component } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import Menu from './Menu';
//import Banner from './Banner';
import Cookies from './Cookies';
import Footer from './Footer';
import Tail from './Tail';

import AnaSayfa from './AnaSayfa';
import Hakkimizda from './Hakkimizda';
import ContactForm from './ContactForm';
import ContactFormFiled from './ContactFormFiled';
import Iletisim from './Iletisim';
import GizlilikPolitikasi from './GizlilikPolitikasi';

class App extends Component {
  state = {
    language: this.getLanguage(),
    visible: (localStorage.getItem("cookiesAccepted") === "Y" ? false : true),
    contactFormSent: false
  }

  getLanguage() {
    let language = localStorage.getItem("userLanguage");
    switch(language) {
      case 'EN':
      case 'DE':
      case 'FR':
      case 'ES':
      case 'TR': 
        return language;
      default:
        return 'EN';
    }
  }

  changeLanguage = (language) => {
    this.setState({ language });
  }

  changeVisible = (visible) => {
    this.setState({ visible });
  }

  changeContactFormSent = (contactFormSent) => {
    this.setState({ contactFormSent });
  }

  render() {
    console.log(window.location.href);
    return (
      <BrowserRouter>
        <Menu language={this.state.language} changeLanguage={this.changeLanguage} />
        <Switch>
          <Route path='/hakkimizda'>
            <Hakkimizda language={this.state.language} />
          </Route>
          <Route path='/iletisim-formu-alindi'>
            <ContactFormFiled
              language={this.state.language}
              changeContactFormSent={this.changeContactFormSent}/>
          </Route>
          <Route path='/iletisim'>
            <Iletisim language={this.state.language} />
          </Route>
          <Route path='/gizlilik-politikasi'>
            <GizlilikPolitikasi language={this.state.language} />
          </Route>
          <Route path='/'>
            <AnaSayfa language={this.state.language} />
          </Route>
        </Switch>
        {/* <Banner language={this.state.language} /> */}
        <ContactForm
          language={this.state.language}
          contactFormSent={this.state.contactFormSent}
          changeContactFormSent={this.changeContactFormSent}/>
        <Footer language={this.state.language} />
        <Tail language={this.state.language} />
        {
          this.state.visible ? 
          <Cookies
            language={this.state.language}
            changeVisible={this.changeVisible}/> :
          null
        }    
      </BrowserRouter>
    )
  }
}

export default App;